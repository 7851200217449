import { Link, useHistory } from 'react-router-dom'
import classnames from 'classnames'
import './styles.scss'
import linkIcon from '../images/link-icon.svg'
import facebookIcon from '../images/facebook.svg';
import facebookHoverIcon from '../images/facebookHover.svg';
import twitterIcon from '../images/twitter.svg';
import twitterHoverIcon from '../images/twitterHover.svg';
import { hintTypes } from '../ResultsList/'
import { useContext } from 'react'
import './styles.scss';
import { useMyStructuredModules } from '../userHooks';
import { ModulePopupContext } from '../App'

const fieldsToIgnore = [
  'id', 'years', 'type', 'Tier',
]

const linkField = (label) => url => <a href={url} className='externalLink' target='_blank' rel="noreferrer">{url}</a>
const itemPreferences = {
  'thinktankpapers': {
    columns: 4,
    order: ['Title', 'Date', 'Author', 'Think Tank', 'Link'],
    customColValues: {
      Link: linkField('Link'),
    }
  },
  'thinktanktestimony': {
    columns: 4,
    order: ['Hearing Title', 'Testimony Title', 'Committee', 'Subcommittee', 'Witness',
      'Witnesss Organization', 'Date', 'Government body', 'Administration', 'Link'],
    customColWidths: {
      'Hearing Title': 2,
      'Testimony Title': 2,
    },
    extraFieldsToIgnore: ['Year'],
    shareColHeight: 4,
    customColValues: {
      Link: linkField('Link'),
    }
  },
  'articles': {
    columns: 4,
    order: ['Title', 'Year', 'Author', 'Journal', 'Link', 'Volume', 'Issue', 'Journal Issue', 'Abstract'],
    customColWidths: {
      Abstract: 4,
    },
  },
  'tvshows': {
    columns: 4,
    order: ['TV Show', 'Season', 'Episode', 'Air Date', 'Channel', 'Director', 'Writer', 'Producer',
      'Production Region', 'Genre', 'Link' ],
    customColValues: {
      Link: linkField('Link'),
    },
    customColWidths: {
      'TV Show': 2,
    },

  },
  'bookreviews': {
    columns: 4,
    order: ['Title', 'Review Title', 'Year', 'Author', 'Review/Author', 'Review Author',
      'Publisher', 'Editor', 'Journal', 'Volume', 'Issue', 'Link'],
    customColValues: {
      Link: linkField('Link'),
    }
  },
  'dissertations': {
    columns: 3,
    order: ['Title', 'Year', 'Author', 'ISBN',
      'Classifications', 'Digital Object Identifiers', 'DissertationID',
      'Subjects', 'Abstract'],
    customColWidths: {
      'Subjects': 4,
      'Abstract': 4,
    }
  },
  'books': {
    columns: 4,
    order: ['Title', 'Year', 'Author', 'Publisher', 'Editor', 'ISBN', 'Link', 'Peer Reviewed', 'Place of Publication', 'Edition', 'Tags', 'Abstract'],
    customColValues: {
      Link: linkField('Link'),
      'Peer Reviewed': (value) => value === '1' ? 'Yes' : (value === '0' ? 'No' : '')
    },
    customColWidths: {
      'Abstract': 4,
    }
  },
  'translatedbooks': {
    columns: 4,
    order: ['Original Title', 'Title', 'Year', 'Author', 'Translator', 'Language',
      'Publisher', 'Editor', 'Place of Publication', 'ISBN', 'Awards', 'Tags', 'Abstract'],
    customColWidths: {
      'Original Title': 2,
      'Title': 2,
      Abstract: 4,
    }
  },
  'films': {
    columns: 4,
    order: ['Film', 'Year', 'Director', 'Writers', 'Production Companys', 'Producers', 'Distributors',
      'Producition Regions', 'Genres', 'LanguagesSubtitles', 'Reviews', 'Links', 'Domestic Box Office USD'],
    customColWidths: {
      'Film': 4,
    },
    customColValues: {
      'Reviews': linkField('Review'),
      'Links': linkField('Link')
    },
    customTH: {
      'Production Companys': 'Production Company',
      'LanguagesSubtitles': 'Languages/Subtitles',
      'Links': 'Link',
    },
    extraFieldsToIgnore: ['Reviews', 'Topic Tropes', 'Notes'],
    shareColHeight: 4,
  },
  'documentaries': {
    columns: 4,
    order: ['Title', 'Year', 'Director', 'Producer', 'Production Company', 'Production Region',
      'Distributor', 'Awards', 'Genre', 'Link'],
    customColValues: {
      'Link': linkField('Link')
    },
  },
  'webpages': {
    columns: 4,
    order: ['Title', 'URL', 'RSS Feed', 'Knowledge Flow', 'Knowledge Flow Variety', 'Language', 'Country of Publication',
      'Type', 'Topic', 'Geographic Focus', 'Format'],
    extraFieldsToIgnore: ['Year', 'Date Digitalized', 'Date Original', 'Format Variety', 'City', 'Date original'],
    customColValues: {
      'URL': linkField('URL'),
    },
    customColWidths: {
      'Title': 2,
    },
    shareColHeight: 4,

  }
}


function makeLinkForItem(item, inThinkTankView) {
  let l = `/item/${item.id}`
  if (inThinkTankView) {
    l = `${l}?b=${encodeURIComponent(window.location.pathname)}`
  }
  return l
}

export default function DetailedItemView(props) {
  const { close, item, clickToSearch, hoverValue, clearValue, inThinkTankView } = props
  const history = useHistory()
  const allBookmarks = useMyStructuredModules().map(d => d.bookmarks)
    .reduce((p, cur) => [...p, ...cur], [])
    .map(d => d.itemId)

  const type = item.type
  let {columns, order, customColWidths, customColValues, customTH, shareColHeight, extraFieldsToIgnore} = itemPreferences[type]
  if (!columns) {
    columns = 1
  }
  if (!order) {
    order = []
  }
  if (!customColWidths) {
    customColWidths = {}
  }
  if (!customColValues) {
    customColValues = {}
  }
  const allFieldsToIngore = [...fieldsToIgnore, ...(extraFieldsToIgnore ? extraFieldsToIgnore : [])]
  const fields = Object.keys(item).filter(field => !allFieldsToIngore.includes(field))
    .sort((a, b) => {
      const aIndex = order.indexOf(a)
      const bIndex = order.indexOf(b)
      if (aIndex === -1 && bIndex === -1) {
        return 0
      } else if (aIndex === -1) {
        return 1
      } else if (bIndex === -1) {
        return -1
      }
      return aIndex - bIndex
    })

  const gridStyle  = {
    // gridTemplateColumns: columns ? Array.from({length: columns + 1}).map(d => 'auto').join(' ') : null,
    gridTemplateColumns: `repeat(${columns}, 1fr) minmax(200px, 20vw)`
  }
  const fieldDivs = fields.map(field => {
    let value = item[field]
    const hint = hintTypes.includes(field) ? field : 'HIDE';
    const displayHint = !inThinkTankView && hint !== 'HIDE';
    const valueStyle = displayHint ? {
      cursor: 'pointer',
      textDecoration: 'underline',
    } : {};
    const dataValue = hint === 'HIDE' || inThinkTankView ? '' : (value || '').trim();
    const onClick = displayHint ? () => clickToSearch({ type: hint, value: dataValue}) : null;
    const fieldStyle = {}
    let isLarge = field === 'Title' || field === 'Original Title' || field === 'Film' || field === 'TV Show' || field === 'Hearing Title' || field === 'Testimony Title'
    if (isLarge) {
      fieldStyle.gridColumn = `span ${columns}`
    }
    if (customColWidths[field]) {
      fieldStyle.gridColumn = `span ${customColWidths[field]}`
    }
    if (customColValues[field]) {
      value = customColValues[field](value)
    }
    return (
      <div key={field} style={fieldStyle} className={classnames('fieldValue', { large: isLarge })}>
        <div className='field'>{customTH && customTH[field] ? customTH[field] : field}</div>
        <div
          className='value'
          style={valueStyle}
          data-hint={hint}
          data-value={dataValue}
          onClick={onClick}
        >
          {value}
        </div>
      </div>
    )
  })

  const shareStyle = {
    gridRowEnd: shareColHeight ? shareColHeight : null,
  }
  const encodedTitle = encodeURIComponent(item[fields[0]])
  const googleSearchLink = `https://google.com/search?q=${encodedTitle}`

  const title = encodeURIComponent('Archiving the Middle East');
  const url = makeLinkForItem(item, inThinkTankView)
  const encodedUrl = encodeURIComponent(`${window.origin}${url}`);
  const shareItems = [
    {
      id: 'Twitter',
      icon: twitterIcon,
      hoverIcon: twitterHoverIcon,
      url: `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${title}`,
    },
    {
      id: 'Facebook',
      icon: facebookIcon,
      hoverIcon: facebookHoverIcon,
      url: `https://www.facebook.com/sharer.php?u=${encodedUrl}`,
    },
  ];
  const { openModulePopup } = useContext(ModulePopupContext)

  const openModuleTooltip = (data, alreadyBookmarked) => (event) => {
    openModulePopup({target: event.target, data, alreadyBookmarked})
  }

  const bookmarked = allBookmarks.includes(item.id)

  const closeHandler = () => {
    const params = new URLSearchParams(window.location.search)
    const backLink = params.get('b')
    if (backLink) {
      history.push(backLink)
    } else {
      close()
    }
  }
  return (
    <div className='DetailedItemView'>
      <div className='buttons'>
        <button className='close' onClick={closeHandler}><span>Back to results</span></button>
        <button className={classnames('bookmark', { marked: bookmarked })} onClick={openModuleTooltip(item, bookmarked)}><span>Bookmark this</span></button>
      </div>
      <div
        className='fields'
        style={gridStyle}
        onMouseMove={hoverValue}
        onMouseOut={clearValue}
      >
        {fieldDivs}
        <div className='share' style={shareStyle}>
          <ul>
            <li>
              <Link to={url}>
                URL for this Record
                <img
                  src={linkIcon}
                  alt='direct link'
                  style={{ height: '1em', marginRight: '0.25em', transform: 'translate(0.25em, 0.125em)'}}
                />
              </Link>
            </li>
            <li>
              <a href={googleSearchLink} rel="noreferrer" target='_blank'>Search Google for this Title</a>
            </li>
            <li>
              <strong>Share this item</strong>
              <div className='social'>
                {shareItems.map(si => (
                  <div key={si.id} className='shareItem'>
                    <a href={si.url} target='_blank' rel='noopener noreferrer'>
                      <img src={si.icon} alt={si.id} />
                      <img src={si.hoverIcon} className='hover' alt={si.id} />
                    </a>
                  </div>
                ))}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
