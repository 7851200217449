import { useEffect, useState, useMemo, useRef, Fragment } from 'react';
import useDimensions from "react-cool-dimensions";
import { scaleLinear } from 'd3-scale'
import { line } from 'd3-shape'
import { extent } from 'd3-array'
import { format } from 'd3-format'
import sortBy from 'lodash.sortby'
import { useSpring, animated } from 'react-spring';
import classnames from 'classnames';

import './DetailedThinkTankFinanceGraph.scss'

const largeMargins = { top: 60, left: 120, bottom: 30, right: 30 };
const smallMargins = { top: 60, left: 72, bottom: 30, right: 30 };
let margins = window.innerWidth <= 960 ? smallMargins : largeMargins;
const rightLabelWidth = 120;

const siFormatter = format(".2s")
const valueFormatter = v => `$${siFormatter(v).replace(/G/, 'B')}`

const Label = (props) => {
  const { fill } = props;
  const opacity = props.opacity || 1;
  const textRef = useRef()
  const [textDimensions, setTextDimensions] = useState(null)
  useEffect(() => {
    if (textRef.current) {
      const dimensions = textRef.current.getBoundingClientRect()
      setTextDimensions(dimensions)
    }
  }, [props.label])
  let backgroundRect = null
  if (textDimensions) {
    const lrPadding = 4
    const tbPadding = 2
    const { width, height } = textDimensions
    backgroundRect = <rect
      width={width + lrPadding * 2}
      x={-lrPadding - width / 2}
      y={-height + tbPadding}
      height={height + tbPadding * 2}
      fill='#E7EDEF'
      stroke='rgba(239, 91, 49, 0.1)'
      filter='url(#dropshadow)'
      rx={5}
    />
  }
  return (
    <g transform={`translate(0, ${props.y})`}>
      {backgroundRect}
      <text style={{ fill }} ref={textRef} opacity={opacity} textAnchor='middle'>{props.label}</text>
    </g>
  );
}

function AnimatedPath(props) {
  const { financeData, lineGen, defaultPath, opacity, strokeWidth, className } = props;

  const data = financeData;
  while (data.length < defaultPath.length) {
    data.push(data[data.length - 1]);
  }

  const spring = useSpring({
    to: { d: lineGen(data), opacity },
    from: { d: lineGen(defaultPath), opacity: 0 },
  });

  return (
    <animated.path
      opacity={spring.opacity}
      strokeWidth={strokeWidth}
      d={spring.d}
      fill='none'
      className={className}
    />
  );
}

function XTick(props) {
  const { i, tick, x, height, stroke, strokeWidth, opacity, strokeDasharray, textFill } = props;

  const spring = useSpring({
    from: { translate: `translate(0, 0)`, opacity: 0 },
    to: { translate: `translate(${x}, 0)`, opacity: 1 },
  })

  return (
    <animated.g
      key={`${tick}-${i}`}
      transform={spring.translate}
      opacity={spring.opacity}
    >
      <line
        y2={height}
        stroke={stroke}
        strokeWidth={strokeWidth}
        opacity={opacity}
        strokeDasharray={strokeDasharray}
      />
      <text
        style={{fill: textFill}}
        y={height + 20}
        opacity={tick % 5 !== 0 && window.innerWidth <= 720 ? 0 : 1}
        textAnchor='middle'
      >
        {tick}
      </text>
    </animated.g>
  );
}

function YTick(props) {
  const { tick, y, width, height, rectHeight, rectFill, textFill, valueFormatter, lastTick, showRect } = props;

  const spring = useSpring({
    from: { translate: `translate(0, ${height})`, opacity: 0 },
    to: { translate: `translate(0, ${y})`, opacity: 1 },
  });

  return (
    <animated.g
      key={tick}
      transform={spring.translate}
      opacity={spring.opacity}
    >
      <rect y={-rectHeight} height={rectHeight} opacity={0.15} fill={rectFill} width={width} />
      <text x={-15} y={6} textAnchor='end' style={{fill: textFill}}>{valueFormatter(tick)}</text>
      {
        lastTick
          ? <line x1={-10} x2={width} stroke='#647A81' />
          : <line x1={-10} stroke='#647A81' opacity={showRect ? 1 : 0.5} />
        }
    </animated.g>
  );
}

function DataTick(props) {
  const { year, x, y, rectSize, displayValue, valueFormatter, value, labelY, opacity, fill, className } = props;

  const spring = useSpring({
    from: { translate: `translate(0, 0)`, opacity: 0 },
    to: { translate: `translate(${x}, ${y})`, opacity: 1 },
  });

  return (
    <animated.g
      key={year}
      transform={spring.translate}
      opacity={opacity}
      className={classnames('dataTick', className)}
    >
      <rect className='' width={rectSize} height={rectSize} x={-rectSize / 2} y={-rectSize / 2} />
      {
        displayValue
          ? <Label label={valueFormatter(value)} y={labelY} opacity={opacity} fill={fill} />
          : null
      }
    </animated.g>
  );
}

export default function DetailedThinkTankFinanceGraph(props) {
  const { thinkTank, comparisonThinkTank, dataKey, allThinkTanks } = props
  const [hoveredYear, setHoveredYear] = useState(null)
  const svgRef = useRef()

  margins = window.innerWidth <= 960 ? smallMargins : largeMargins;

  const allYears = useMemo(() => (
    sortBy([...new Set(
      allThinkTanks
        .map(t => t.finances.map(f => f.year))
        .reduce((a, v) => a.concat(v), [])
    )])
  ), [allThinkTanks]);

  const { finances } = thinkTank
  const dimensions = useDimensions();
  const { observe } = dimensions;
  const svgWidth = Math.max(200, dimensions.width);

  const dataAccessor = useMemo(() => (d => d[dataKey]), [dataKey]);

  const bothFinancesWithData = useMemo(() => (
    (comparisonThinkTank
      ? comparisonThinkTank.finances.concat(finances)
      : finances
    ).filter(dataAccessor)
  ), [finances, comparisonThinkTank, dataAccessor]);

  const { yearExtent, valueExtent, xTickArray } = useMemo(() => {
    const yearExtent = extent(bothFinancesWithData, d => d.year)
    const valueExtent = extent(bothFinancesWithData, dataAccessor)

    const xTickArray = [];
    const [min, max] = yearExtent;
    for (let x = min; x <= max; x++) {
      xTickArray.push(x);
    }
    return { yearExtent, valueExtent, xTickArray };
  }, [bothFinancesWithData, dataAccessor]);

  const financesWithData = useMemo(() => (
    allYears.map(y => {
      const d = finances.find(f => f.year === y);
      if (d) {
        return d;
      }
      return null;
    }).filter(d => d).filter(dataAccessor)
  ), [allYears, finances, dataAccessor]);

  const comparisonFinancesWithData = useMemo(() => (
    comparisonThinkTank
      ? allYears
        .map(y => {
          const d = comparisonThinkTank.finances.find(f => f.year === y);
          if (d) {
            return d;
          }
          return null;
        })
        .filter(d => d)
        .filter(dataAccessor)
      : []
  ), [allYears, comparisonThinkTank, dataAccessor]);

  const { width, height, svgHeight, xScale, minVal, yScale, lineGen, yTickArray } = useMemo(() => {
    // const svgWidth = width
    const width = svgWidth - margins.left - margins.right
    const height = width * 0.4
    const svgHeight = height + margins.top + margins.bottom

    const xScale = scaleLinear()
      .domain(yearExtent)
      .range([0, width - rightLabelWidth])

    const minVal = Math.min(0, valueExtent[0])

    const yScale = scaleLinear()
      .domain([minVal, valueExtent[1]])
      .range([height, 0])
      .nice();

    const lineGen = line()
      .x(d => xScale(d.year))
      .y(d => yScale(dataAccessor(d)))
      .defined(d => dataAccessor(d) !== undefined)

    const yTickArray = yScale.ticks(8);

    return { width, height, svgHeight, xScale, minVal, yScale, lineGen, yTickArray };
  }, [svgWidth, yearExtent, valueExtent, dataAccessor]);

  const xTicks = useMemo(() => (
    [...new Set(xTickArray)].map((tick, i) => {
      const x = xScale(tick)
      if (Math.round(tick) !== tick) {
        return null
      }
      const highlight = tick % 5 === 0
      let strokeWidth = highlight ? 1 : 0.5
      let stroke = highlight ? '#647A81' : '#A5B2B5'
      let textFill = highlight ? '#112227' : '#A5B2B5'
      let opacity = 0.5;
      let strokeDasharray = null;
      if (hoveredYear === tick) {
        strokeWidth = 1;
        stroke = '#19619B';
        textFill = '#12609D';
        opacity = 1;
        strokeDasharray = '4 4';
      }

      return (
        <XTick
          key={i}
          i={i}
          tick={tick}
          x={x}
          height={height}
          stroke={stroke}
          strokeWidth={strokeWidth}
          opacity={opacity}
          strokeDasharray={strokeDasharray}
          textFill={textFill}
        />
      );
    }).filter(d => d)
  ), [xTickArray, height, hoveredYear, xScale]);

  const defaultPath = useMemo(() => (
    allYears.map(year => {
      const d = {
        year,
        duplicate: true,
      };
      d[dataKey] = minVal;
      return d;
    })
  ), [allYears, dataKey, minVal]);

  const paddedFinancesWithData = useMemo(() => {
    const paddedFinancesWithData = financesWithData.slice();
    while (paddedFinancesWithData.length < defaultPath.length) {
      const duplicate = {
        ...paddedFinancesWithData[paddedFinancesWithData.length - 1],
        duplicate: true,
      };
      paddedFinancesWithData.push(duplicate);
    }
    return paddedFinancesWithData;
  }, [financesWithData, defaultPath.length]);

  const linePath = useMemo(() => (
    <AnimatedPath
      financeData={paddedFinancesWithData}
      opacity={1}
      lineGen={lineGen}
      defaultPath={defaultPath}
      strokeWidth={2}
      className='financeLine'
    />
  ), [paddedFinancesWithData, lineGen, defaultPath]);

  const paddedComparisonFinancesWithData = useMemo(() => {
    const paddedComparisonFinancesWithData = comparisonFinancesWithData.length
      ? comparisonFinancesWithData.slice()
      : defaultPath;
    while (paddedComparisonFinancesWithData.length < defaultPath.length) {
      const duplicate = {
        ...paddedComparisonFinancesWithData[paddedComparisonFinancesWithData.length - 1],
        duplicate: true,
      };
      paddedComparisonFinancesWithData.push(duplicate);
    }
    return paddedComparisonFinancesWithData;
  }, [comparisonFinancesWithData, defaultPath]);

  const comparisonPath = useMemo(() => (
    <AnimatedPath
      financeData={paddedComparisonFinancesWithData}
      opacity={comparisonFinancesWithData.length ? 1 : 0}
      lineGen={lineGen}
      defaultPath={defaultPath}
      strokeWidth={2}
      className='comparisonLine'
    />
  ), [paddedComparisonFinancesWithData, comparisonFinancesWithData.length, lineGen, defaultPath]);

  const allPaths = useMemo(() => (
    allThinkTanks.map((thinkTank, index) => {
      const data = thinkTank.finances.filter(dataAccessor)
      return (
        <AnimatedPath
          key={index}
          financeData={data.length ? data : defaultPath}
          opacity={data.length ? 0.2 : 0}
          lineGen={lineGen}
          defaultPath={defaultPath}
          strokeWidth={1}
          className='other'
        />
      );
    })
  ), [allThinkTanks, dataAccessor, defaultPath, lineGen]);

  const yTicks = useMemo(() => {
    const rectHeight = yScale(yTickArray[0]) - yScale(yTickArray[1])
    const yTicks = yTickArray.map((tick, tickIndex) => {
      const y = yScale(tick)
      const showRect = tickIndex % 2 === 0;
      const lastTick = tickIndex === yTickArray.length - 1;
      const fillRect = showRect && !lastTick;
      const textFill = showRect || lastTick ? '#112227' : '#A5B2B5'
      const rectFill = fillRect ? '#E7E7ED' : 'transparent'

      return (
        <YTick
          key={tickIndex}
          tick={tick}
          y={y}
          width={width}
          height={height}
          rectHeight={rectHeight}
          rectFill={rectFill}
          textFill={textFill}
          valueFormatter={valueFormatter}
          lastTick={lastTick}
          showRect={showRect}
        />
      );
    });
    return yTicks;
  }, [yTickArray, yScale, width, height]);

  const { dataLabel, comparisonLabel} = useMemo(() => {
    const minDifference = 52;
    let dataLabelY = financesWithData.length ? (
        Math.min(height - minDifference, yScale(financesWithData[financesWithData.length - 1][dataKey]))
      ) : null;
    let comparisonLabelY = comparisonFinancesWithData.length > 1 ? (
        Math.min(height - minDifference, yScale(comparisonFinancesWithData[comparisonFinancesWithData.length - 1][dataKey]))
      ) : null;
    const difference = dataLabelY - comparisonLabelY;

    if (difference >= 0 && difference < minDifference) {
      comparisonLabelY -= (minDifference - difference);
    } else if (difference < 0 && difference > -minDifference) {
      dataLabelY -= (minDifference + difference);
    }

    const dataLabel = dataLabelY === null ? null : (
      <div
        className='pathLabel'
        style={{
          marginTop: `${dataLabelY}px`,
        }}
      >
        <span>
          {thinkTank.thinkTank}
        </span>
      </div>
    );

    const comparisonLabel = comparisonLabelY === null || !comparisonThinkTank ? null : (
      <div
        className='pathLabel comparison'
        style={{
          marginTop: `${comparisonLabelY}px`,
        }}
      >
        <span>
          {comparisonThinkTank.thinkTank}
        </span>
      </div>
    );

    return { dataLabel, comparisonLabel };
  }, [financesWithData, comparisonFinancesWithData, comparisonThinkTank, dataKey, height, thinkTank.thinkTank, yScale]);

  const dataTicks = useMemo(() => {
    const tickTypes = [paddedFinancesWithData, paddedComparisonFinancesWithData]
    const tickData = tickTypes.map((data, tickTypeIndex) => {
      return data.map((financeRow, i) => {
        const { year, duplicate } = financeRow;
        const value = dataAccessor(financeRow)

        const x = xScale(year)
        const y = yScale(value)
        const displayValue = duplicate
          ? false
          : hoveredYear
            ? year === hoveredYear // year >= hoveredYear - 3 && year <= hoveredYear + 3
            : year % 5 === 0;
        const opacity = duplicate
          ? 0
          : hoveredYear
            ? year === hoveredYear ? 1 : 0.5
            : 1

        return { year, tickTypeIndex, i, x, y, displayValue, value, opacity}
      })
    })
    const minYear = Math.min(tickData[0][0].year, tickData[1][0].year)
    const maxYear = Math.max(tickData[0][tickData[0].length - 1].year, tickData[1][tickData[1].length - 1].year)

    const years = []
    for (let i = minYear; i <= maxYear; i++) {
      years.push(i)
    }
    return years.map(year => {
      const rectSize = 6
      const tankDatum = tickData[0].find(d => d.year === year)
      const comparisonDatum = tickData[1].find(d => d.year === year)
      let comparisonGreater = false;

      if (tankDatum && comparisonDatum) {
        comparisonGreater = comparisonDatum.value > tankDatum.value
      }
      let dataTick = null

      if (tankDatum) {
        const labelY = comparisonGreater ? 24 : -20
        dataTick = <DataTick
          key={`f-${tankDatum.tickTypeIndex}-${tankDatum.i}`}
          year={tankDatum.year}
          x={tankDatum.x}
          y={tankDatum.y}
          // from={from}
          rectSize={rectSize}
          displayValue={tankDatum.displayValue}
          valueFormatter={valueFormatter}
          value={tankDatum.value}
          labelY={labelY}
          opacity={tankDatum.opacity}
          fill='#f15a24'
          className=''
        />
      }
      let comparisonDataTick = null
      if (comparisonDatum) {
        const comparisonLabelY = comparisonGreater ? -20 : 24
        comparisonDataTick =  <DataTick
          key={`c-${comparisonDatum.tickTypeIndex}-${comparisonDatum.i}`}
          year={comparisonDatum.year}
          x={comparisonDatum.x}
          y={comparisonDatum.y}
          // from={from}
          rectSize={rectSize}
          displayValue={comparisonDatum.displayValue}
          valueFormatter={valueFormatter}
          value={comparisonDatum.value}
          labelY={comparisonLabelY}
          opacity={comparisonDatum.opacity}
          fill='#12609D'
          className='comparison'
        />
      }
      return (
        <Fragment key={year}>
          {dataTick}
          {comparisonDataTick}
        </Fragment>
      )
    })

  }, [paddedFinancesWithData, paddedComparisonFinancesWithData, hoveredYear, dataAccessor, xScale, yScale]);

  const hoverGraph = (event) => {
    const svgLeft = svgRef.current.getBoundingClientRect().left
    const x = event.clientX - margins.left - svgLeft
    const year = Math.round(xScale.invert(x))
    setHoveredYear(year)
  }

  const hoverOffGraph = () => {
    setHoveredYear(null)
  }

  const clipPathId = 'financeGraphClipPath';
  return financesWithData.length === 0 ? null : (
    <div className='DetailedThinkTankFinanceGraph' ref={observe}>
      <svg ref={svgRef} width={svgWidth} height={svgHeight} onMouseMove={hoverGraph} onMouseOut={hoverOffGraph}>
        <defs>
          <clipPath id={clipPathId}>
            <rect width={width} height={yScale(0)} />
          </clipPath>
          <filter id="dropshadow" height="130%">
            <feGaussianBlur in="SourceAlpha" stdDeviation="3"/>
            <feOffset dx="0" dy="1" result="offsetblur"/>
            <feComponentTransfer>
              <feFuncA type="linear" slope="0.5"/>
            </feComponentTransfer>
            <feMerge>
              <feMergeNode/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
        </defs>
        <g transform={`translate(${margins.left}, ${margins.top})`}>
          <text className='title' y={-margins.top * 0.3} textAnchor='end'>{dataKey}</text>
          <line y1={height} y2={height} stroke="#647a81" x2={width} />
          <g>
            {xTicks}
          </g>
          <g>
            {yTicks}
          </g>
          <g clipPath={`url(#${clipPathId})`}>
            <rect width={width} height={height} fill='transparent' />
            <g opacity={comparisonFinancesWithData.length ? 0.5 : 1}>
              {allPaths}
            </g>
            {comparisonPath}
            {linePath}
          </g>
          {dataTicks}
        </g>
      </svg>
      <div
        className='pathLabels'
        style={{
          top: margins.top,
          bottom: margins.bottom,
          right: margins.right,
          width: rightLabelWidth,
        }}
      >
        {comparisonLabel}
        {dataLabel}
      </div>
    </div>
  )
}

