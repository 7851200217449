import { useState } from 'react'
import classnames from 'classnames'

import './styles.scss';

export default function CollapsableContainer(props) {
  const [collapsed, setCollapsed] = useState(false);
  const { name, count, children, wrapperOnly} = props
  return (
    <div className='collapsableContainer'>
      <div className='titleBar'>
        <div className='control'>
          <div
            className={classnames('checkboxOuter', { unchecked: collapsed, wrapperOnly })}
            onClick={() => setCollapsed(collapsed => !collapsed)}
          >
            <div className='checkbox' />
          </div>
        </div>
        <div className='name'>
          <h4>
            {name}
          </h4>
        </div>
        <div className='count'>
          <span>
            {count}
          </span>
        </div>
      </div>
      <div className={classnames('listContainer', { collapsed })}>
        {children}
      </div>
    </div>
  )
}
