import { useState, useRef } from 'react';
import sortBy from 'lodash.sortby';

import { useIndividualThinkTankData } from './thinkTankHooks'
import ResultsList from '../ResultsList'
import DetailedThinkTankFinanceGraph from './DetailedThinkTankFinanceGraph'

import './DetailedThinkTankView.scss'
import CollapsableContainer from '../ui/CollapsableContainer';

const thinkTankTypes = ['thinktankpapers', 'thinktanktestimony']

export default function DetailedThinkTankView(props) {
  const [comparisonThinkTank, setComparisonThinkTank] = useState();

  const { thinkTank, allThinkTanks } = props
  const data = useIndividualThinkTankData(thinkTank)
  const thinkTankNames = ['(Select a Think Tank)'].concat(
      allThinkTanks
        .filter(t => t.finances.length)
        .map(t => t.thinkTank)
        .filter(t => t !== thinkTank.thinkTank)
    );

  const listRefs = useRef({})
  const graphKeys = [
    'Total Revenue',
    'Total Expenses',
    'Net Assets',
  ]

  return (
    <div className='DetailedThinkTankView'>
      <div className='thinkTank'>
        <div className='logo'>
          <img src={`${process.env.PUBLIC_URL}/logos/${thinkTank.thinkTank.toLowerCase().replace(/ /g, '_').replace(/[^_a-z0-9]/g, '')}.png`} alt='' />
        </div>
        <h2>{thinkTank.thinkTank}</h2>
      </div>
      {thinkTankTypes.map(type => {
        if (!data || !data[type]) {
          return null
        }
        return <ResultsList
          key={type}
          collection={type}
          values={sortBy(data[type], d => new Date(d._source.date)).reverse()}
          listRefs={listRefs}
          showSearchResults
          thinkTankList
          inThinkTankView
        />
      })}
      <CollapsableContainer name='Finances'>

        {thinkTank.finances.length < 1 ? null : (
          <div className='comparison'>
            <div className='current'>
              {/* <span>Displaying financials for <span className='thinkTank'>{thinkTank.thinkTank}</span></span> */}
            </div>
            <div className='alternate'>
              <div className='label'>
                <span>Compare to:</span>
              </div>
              <select
                className='comparisonThinkTank'
                value={comparisonThinkTank}
                onChange={(event) => setComparisonThinkTank(event.target.value, null)}
              >
                {thinkTankNames.map(t => {
                  return <option key={t} value={t}>{t}</option>
                })}
              </select>
            </div>
          </div>
        )}
        {graphKeys.map(graphKey => (
          <DetailedThinkTankFinanceGraph
            key={graphKey}
            dataKey={graphKey}
            comparisonThinkTank={allThinkTanks.find(t => t.thinkTank === comparisonThinkTank)}
            {...props}
          />
        ))}
      </CollapsableContainer>
    </div>
  )

}
