import { useHistory } from "react-router"
import { useAuth, useUser } from "reactfire"


import './styles.scss'
import ProfileModuleList from "./ProfileModuleList"
import EditLoginInfo from "./EditLoginInfo"
import SearchHistory from "./SearchHistory"
export default function Profile(props) {

  const user = useUser()
  const auth = useAuth()
  const history = useHistory()
  const ready = user && user.data

  const logout = async () => {
    try {
      await auth.signOut()
    } catch (error) {
      console.log(error)
    }
    history.push('/')
  }

  if (!ready) {
    return null;
  }

  return (
    <div className='Profile'>
      <div>Welcome {user.data.displayName}</div>
      <button className='logout' onClick={logout}>Logout</button>
      <EditLoginInfo />
      <ProfileModuleList email={user.data.email} />
      <SearchHistory />
    </div>

  )
}