
import { useHistory, useParams } from 'react-router-dom'
import DetailedItemView from '../DetailedItemView'
import { useGetItemById } from '../userHooks';
export default function SingleItem(props) {
  let { itemId } = useParams();
  const history = useHistory()
  const item = useGetItemById(itemId)

  const closeItem = () => {
    history.replace('/')
  }
  return (
    <div>
      {item ?
        <DetailedItemView close={closeItem} item={item} />
        : null
      }

    </div>
  )
}

