const fieldsToRemove = [
  'id',
  'type',
  'years',
  'Volume',
  'Issue',
  'Tier',
  // 'Review',
  // 'Topic Tropes',
  // 'Ratings',
  // 'Notes',
  'Awards',
  'Season',
  'Channel',
  'LanguagesSubtitles',
  // 'Reviews',
  // 'Domestic Box Office USD',
  // 'Production Cost USD',
  // 'Viewed by',
  // 'International Box Office USD',
  // 'Industry Awards',
  'Administration',
  'Language',
  'Place of Publication',
  'DissertationID',
  'RSS Feed',
  'Knowledge Flow',
  'Knowledge Flow Variety',
  'Format',
  'Format Variety',
  'Type',
  // 'Geographic Focus',
  'Country of Publication',
  'Date Digitalized',
  'City',
  'Date original',
  'Keywords',
  'Traffic month',
]

let uniqueFields = [
  'Review Author',
  'Author',
  'Title',
  'Volume',
  'Issue',
  'Year',
  'Tier',
  'Journal',
  'id',
  'type',
  'years',
  'Review Title',
  'Publisher',
  'URL',
  'RSS Feed',
  'Knowledge Flow',
  'Knowledge Flow Variety',
  'Format',
  'Format Variety',
  'Type',
  'Topic',
  'Country of Focus',
  'Language',
  'Country of Publication',
  'Date Digitalized',
  'Region of Focus',
  'City',
  'Date original',
  'Keywords',
  'Traffic month',
  'Director',
  'Producer',
  'Production Company',
  'Distributors',
  'Production Region',
  'Genre',
  'Link',
  'Awards',
  'TV Show',
  'Episode Title',
  'Season',
  'Episode',
  'Air Date',
  'Channel',
  'Writer',
  'Film',
  'Writers',
  'Production Companys',
  'Producers',
  'Production Regions',
  'Genres',
  'LanguagesSubtitles',
  'Think Tank',
  'Date',
  'Government Body',
  'Committee',
  'Subcommittee',
  'Hearing Title',
  'Testimony Title',
  'Witness',
  'Administration',
  'Witnesss Organization',
  'Original Title',
  'Translator',
  'ISBN',
  'Place of Publication',
  'Tags',
  'Abstract',
  'Editor',
  'Peer Reviewed',
  'Series',
  'Edition',
  'Digital Object Identifiers',
  'Classifications',
  'Subjects',
  'DissertationID'
]

fieldsToRemove.forEach(field => {
  const exists = uniqueFields.includes(field)
  if (!exists) {
    console.log(`trying to remove field that doesnt seem to exist`, field)
  }
})

uniqueFields = uniqueFields.filter(field => !fieldsToRemove.includes(field))

export default uniqueFields
