import { useState, useMemo, useRef, useEffect } from 'react';
import { scaleLinear, scaleSqrt, scaleLog } from 'd3-scale';
import { interpolateRgb } from 'd3-interpolate';
import classnames from 'classnames';
import sortBy from 'lodash.sortby';
import throttle from 'lodash.throttle';
import rangeMarker from '../images/rangeMarker.svg';
import Toggle from '../ui/Toggle/'
import useDimensions from "react-cool-dimensions";
import { useSpring, animated } from 'react-spring'
import { collectionNames } from '../Search'
import infoIcon from '../images/info_icon.svg';
import './styles.scss';
import ReactTooltip from 'react-tooltip'
const colorRange = ['#ADDFEC', '#ACD0E5', '#1F73B7', '#4C489A', '#272560', '#F15A24'];

const colorScale = scaleLinear()
  .range(colorRange)
  .interpolate(interpolateRgb);

export const zeroAdjustedColorScale = (value) => {
  if (value === 0) {
    return '#fff'
  }
  return colorScale(value)
}
const stepScale = scaleLog()
  .domain([colorRange.length - 1, 1]);

const sizeScale = scaleSqrt()
  .range([32, 100])
  .clamp(true);

function AnimatedRect(props) {
  const { className, dataYear, dataType, dataCount, dataCumulative, dataHighestCount,
    dataHighestYear, fill, stroke, width, x, height, y, xIndex, yIndex, opacity, onClick, clickable } = props

  const spring = useSpring({
    to: { width, x, y, height, opacity, },
    from: {width, x, y, height, opacity: 0, },
    delay: (xIndex + yIndex) * 60,
  })
  const style = {
    cursor: clickable ? 'hand' : null
  }
  return (
    <animated.rect
      style={style}
      className={className}
      data-year={dataYear}
      data-type={dataType}
      data-count={dataCount}
      data-cumulative={dataCumulative}
      data-highestcount={dataHighestCount}
      data-highestyear={dataHighestYear}
      fill={fill}
      stroke={stroke}
      width={spring.width}
      x={spring.x}
      y={spring.y}
      height={spring.height}
      opacity={spring.opacity}
      rx={2}
      onClick={onClick}
    />
  )
}

export default function Heatmap(props) {
  let { inList, visible, term, collection, data, years, listRefs, filter, accumulate, rectClickHandler } = props;
  const heatmap = useRef();
  const [showStreamgraph, setShowStreamgraph] = useState(false);
  const [rectOpacity, setRectOpacity] = useState(0)
  const clickable = !inList
  useEffect(() => {
    let id = setTimeout(() => {
      setRectOpacity(1)
    }, 1000)
    return () => clearTimeout(id)
  }, [])
  const [tooltip, setTooltip] = useState({
    visible: false,
    year: '',
    type: '',
    count: '',
    cumulative: '',
    highestcount: '',
    highestyear: '',
  });

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  const metricKey = accumulate ? 'cumulative' : 'count';
  const colorMax = Math.max(colorRange.length, ...data.map(d => d.years.map(y => y[metricKey])).reduce((a, v) => a.concat(v), []));
  if (filter) {
    data = data.filter(filter)
  }
  stepScale.range([1, colorMax]);
  const colorDomain = useMemo(() => (
    [...new Set(colorRange.map((d, i) => (
      i === 0 ? 0 : stepScale(colorRange.length - i)
    )))]
  ), [colorMax]); // eslint-disable-line react-hooks/exhaustive-deps
  const gradientSpacing = colorRange.map((d, i) => i === 0 ? null : (
    `${d} ${stepScale(colorRange.length - i) / colorMax * 100}%`
  )).filter(d => d);
  colorScale.domain(colorDomain);
  sizeScale.domain([1, colorMax]);
  const decimals = useMemo(() => (colorMax < colorRange.length - 1 ? 1 : 0), [colorMax]);

  const spectrum = useMemo(() => (
    inList ? null :
    <div className='spectrum'>
      <div className='zero'>
        <div className='ticks'>
          <div key={0} className='tick' style={{ left: `${0}%` }}>
            <span>
              0
            </span>
            <div className='marker'>
              <img src={rangeMarker} alt='' />
            </div>
          </div>
        </div>
        <div className='gradient' />
      </div>
      <div className='range'>
        <div className='ticks'>
          {colorDomain.filter(tick => tick > 0).map((tick, i) => (
            <div key={i} className='tick' style={{ left: `${Math.min(99.9, tick / colorMax * 100)}%` }}>
              <span>
                {(+tick.toFixed(decimals)).toLocaleString()}
                {/* will need to show decimal < certain number of results */}
              </span>
              <div className='marker'>
                <img src={rangeMarker} alt='' />
              </div>
            </div>
          ))}
        </div>
        <div className='gradient' style={{ background: `linear-gradient(to right, ${gradientSpacing.join(', ')})` }} />
      </div>
    </div>
  ), [colorDomain, colorMax, gradientSpacing, decimals, inList]);


  const rowHeight = 22
  const heatmapHeight = data.length * rowHeight

  const { observe, width } = useDimensions();
  const heatmapWidth = width
  const heatmapMargins = {
    // left: 166, top: 1, right: 1, bottom: 30
    left: 182, top: 1, right: -2, bottom: 30
  }
  const yearWidth = (heatmapWidth - (heatmapMargins.left + heatmapMargins.right)) / years.length

  let onlineSourceInfoIcon = useRef(null)
  const typeGrid = useMemo(() => {
    const clickHandler = (year, collection) => event => {
      if (!clickable) {
        return
      }
      rectClickHandler(year, collection)
    }
    if (yearWidth < 0) {
      return null
    }
    onlineSourceInfoIcon.current = null
    return data.map((type, typeIndex) => {
      const [highest] = sortBy(type.years, y => -y.count);
      const name =  collectionNames[type.key]
      const y = rowHeight * typeIndex + heatmapMargins.top
      const showOnlineSourcesIcon = !inList && name === 'Online Sources'
      if (showOnlineSourcesIcon) {
        const onlineSourcesExplainer = 'Due to difficulties in finding an accurate publication date for some websites, we have used 2021 as a placeholder date for all websites without a publication date, explaining the spike in the number of records for Online Sources in that year. We are working to find a solution to this issue.'
        onlineSourceInfoIcon.current = <img
          style={{ pointerEvents: 'all', position: 'absolute', top: '0', left: '0',
          transform: `translate(${heatmapMargins.left - 30}px, ${y + 3}px)`
        }}
          alt={onlineSourcesExplainer}
          data-tip={onlineSourcesExplainer}
          src={infoIcon} width={14} height={14} />
      }
      return (

        <g className='type' key={type.key} >
          <text
            y={14}
            x={-16 + ( showOnlineSourcesIcon ? -20 : 0)}
            textAnchor='end'
            className='label'
            transform={`translate(0, ${y})`}
            onClick={() => {
              const currentRef = listRefs.current[type.key];
              if (currentRef) {
                currentRef.scrollIntoView({ behavior: 'smooth' })
              }
            }}>
              {inList ? (accumulate ? 'Accumulation' : 'Year by year') : name}
          </text>

          <g className='years'>
            {type.years.map((year, yearIndex) => {
              const value = year[metricKey];
              const background = zeroAdjustedColorScale(value);
              const borderColor = value === 0 ? '#E7EDEF' : background;
              const x = yearIndex * yearWidth

              return (
                <AnimatedRect
                  key={year.year}
                  className='year'
                  dataYear={year.year}
                  dataType={name}
                  dataCount={year.count}
                  dataCumulative={year.cumulative}
                  dataHighestCount={highest.count}
                  dataHighestYear={highest.year}
                  fill={background}
                  stroke={borderColor}
                  width={yearWidth - 3}
                  x={x}
                  y={1 + y}
                  height={rowHeight - 4}
                  rx={2}
                  index={yearIndex + typeIndex * years.length}
                  xIndex={yearIndex}
                  yIndex={typeIndex}
                  opacity={rectOpacity}
                  onClick={clickHandler(+year.year, type.key)}
                  clickable={clickable && year.count > 0}
                />
              );
            })}
          </g>

        </g>
      )
    })
  }, [data, metricKey, listRefs, inList, heatmapMargins.top, yearWidth, years.length, accumulate, rectOpacity, clickable, rectClickHandler, heatmapMargins.left]);

  const yearTicks = useMemo(() => {
    const wide = yearWidth > (12 * 3);
    return (
      years.map((year, yearIndex) => {
        const x = yearWidth * ( yearIndex + 0.5) - 2
        const interval = inList
          ? wide ? 5 : 10
          : wide ? 1 : 5;
          // (inList ? 10 : 5)
        const opacity = +year % interval === 0 ? 1 : 0;
        const transform = `translate(${x}, ${heatmapHeight + heatmapMargins.top})`
        return (
          <g key={year} className='year' style={{ opacity }} transform={transform}>
            <line y1={-2} y2={5} />
            <text y={17} textAnchor='middle'>{year}</text>
          </g>
        )
      })
    );
  }, [years, inList, heatmapMargins.top, yearWidth, heatmapHeight]);
  return (
    <div className={classnames('Heatmap', { visible, inList })}>
      <h3>
        <span>
          Knowledge Production {term.length ? `about “${term}”` : ''} Over Time
        </span>
      </h3>
      { true ? null :
        <Toggle
          name='streamgraph'
          value={showStreamgraph}
          setValue={setShowStreamgraph}
          label1='Hide Streamgraph'
          label2='Show Streamgraph'
        />
      }

      <div ref={node => {
          observe(node)
          heatmap.current =  node
        }} className='chart'>
        { inList ? null :
          <div className='legend'>
            <div className='label' style={{ marginTop: '0.75em' }}>
              <span>
                {accumulate ? `Accumulation Of ${collection}` : `Number Of ${collection}`}
              </span>
            </div>
            {spectrum}
          </div>
        }
        <div style={{ position: 'relative' }}>
          <svg width={heatmapWidth} height={heatmapHeight + heatmapMargins.top + heatmapMargins.bottom}>

            <g transform={`translate(${heatmapMargins.left}, ${heatmapMargins.top})`}
              className='types'
              onMouseMove={throttle(e => {
                const { target } = e;
                const { dataset } = target;
                if (dataset.tip) {
                  return true
                }
                if (Object.keys(dataset).length) {
                  const parent = heatmap.current.getBoundingClientRect()
                  const { x, y, width, height } = target.getBoundingClientRect();

                  const flipX = (x + width) > heatmapWidth / 2
                  const posX = x + (flipX ? -3 : width - 3) - parent.x;
                  // console.log(posX, x,  dataset)
                  const pos = {
                    x: posX,
                    flipX,
                    y: !inList
                      ? y + height - parent.y
                      : y - (parent.y + (height * 5)), // do some checks to flip up or down here?
                  };
                  setTooltip({
                    visible: true,
                    ...dataset,
                    ...pos,
                  });
                }
              }, { leading: true, trailing: true }, 50)}
              onMouseOut={() => {
                setTooltip(tooltip => tooltip.visible ? { ...tooltip, visible: false } : tooltip);
              }}>

              {typeGrid}
              <g className='years'>
                {yearTicks}
              </g>
            </g>
          </svg>
          {onlineSourceInfoIcon.current}
        </div>
        <div
          className={classnames('tooltip', { visible: tooltip.visible })}
          style={{ transform: `translate(${tooltip.x}px, ${tooltip.y}px) ${tooltip.flipX ? 'translateX(-100%)' : ''}` }}
        >
          <div className={classnames('hint', { clickable: clickable && tooltip.count !== '0' })}>
            <span>
              Click to view <strong>{tooltip.type}</strong> in <strong>{tooltip.year}</strong>
            </span>
          </div>
          <div className='type'>
            <span>
              Production of <br/>
              {tooltip.type}
            </span>
          </div>
          <div className='row'>
            <div className='cell'>
              <span>
                In {tooltip.year}
              </span>
            </div>
            <div className='cell'>
              <span>
                {(+tooltip.count).toLocaleString()}
              </span>
            </div>
          </div>
          <div className='row total'>
            <div className='cell'>
              <span>
                {
                  true // accumulate
                    ? `Total by ${tooltip.year}`
                    : `Total Accumulated in ${tooltip.highestyear}`
                }
              </span>
            </div>
            <div className='cell'>
              <span>
                {
                  true // accumulate
                    ? (+tooltip.cumulative).toLocaleString()
                    : `${tooltip.highestcount}`
                }
              </span>
            </div>
          </div>
        </div>
        <div className='xAxis'>
          <div className='label' />
          <div className='years'>

          </div>
        </div>
      </div>
      <ReactTooltip className='myTooltip' />
    </div>
  );
}
