import { useState, useEffect } from "react"
import axios from 'axios'
import { group } from "d3-array"

import thinkTankSummaryDataFile from '../data/thinktankData.txt'

function toNumber(string) {
  return +(string.replace(/[^0-9]/g, ''));
}

export function useThinkTankData() {
  const [thinkTankData, setThinkTankData] = useState([])
  useEffect(() => {
    window.fetch(thinkTankSummaryDataFile)
      .then(d => d.json())
      .then(data => {
        const thinkTankData = data.map(d => {
          d.key = d.thinkTank.replace(/[^a-zA-Z]/g, '');
          d.finances = d.finances.map(y => {
            y.year = toNumber(y.Year);
            y.revenue = y['Total Revenue'] || 0;
            return y;
          });
          return d;
        });
        setThinkTankData(thinkTankData)
      })
  }, [])
  return thinkTankData
}

export function useIndividualThinkTankData(thinkTank) {
  const [data, setData] = useState(null)
  useEffect(() => {
    const makeRequest = async () => {
      const thinkTankName = `"${thinkTank.thinkTank}"`
      const request = {
        method: 'post',
        url: `${process.env.REACT_APP_ELASTIC_NODE}/myindex/_search`,
        data: {
          query: {
            bool: {
              filter: [
                { terms: { 'type': ['thinktanktestimony', 'thinktankpapers'] } }
              ],
              must: {
                query_string: {
                  query : thinkTankName,
                  fields: ['Witnesss Organization', 'Think Tank']
                },
              }
            }
          },
          size: 10000,
        },
        headers: {
          'Authorization': `ApiKey ${process.env.REACT_APP_ELASTIC_TOKEN}`
        },
        crossDomain: true,
      }
      const results = await axios(request)
      if (results.data && results.data.hits && results.data.hits.hits) {
        const sourceResults = results.data.hits.hits
        const byType = Array.from(group(sourceResults, d => d._source.type))
        const byTypeMap = {}
        byType.forEach(([type, results]) => {
          byTypeMap[type] = results
        })
        setData(byTypeMap)
      }
    }
    makeRequest()


  }, [thinkTank])
  return data
}
