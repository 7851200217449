import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom'
import { parse } from 'query-string';
import axios from 'axios'
import { group } from 'd3-array'
import classnames from 'classnames'

import Toggle from '../ui/Toggle/';
import Heatmap from '../Heatmap';
import ResultsList from '../ResultsList';
import defaultSummaryData from '../data/summaryData.json';
import Checkbox from '../ui/Checkbox'
import uniqueFields from '../data/uniqueFields'
import caret from '../images/caret.svg'
import close from '../images/close-dark.svg';
import resetIcon from '../images/reset-icon.svg';
import resetHovered from '../images/reset-hovered.svg';
import loading from '../images/loading.svg';
import question from '../images/question.svg';
import { useSearchHistory } from '../userHooks'
import './styles.scss'
import { useUser } from 'reactfire';

// if needs to be larger than 10k some additional configuration is probably required
// https://www.elastic.co/guide/en/elasticsearch/reference/current/index-modules.html
const maxResults = 10000

function mapToNest(map) {
  return Array.from(map, ([key, values]) => ({key, values}));
}

export const collectionNames = {
  'thinktankpapers': "Think Tank Publications",
  'thinktanktestimony': 'Congressional Testimonies',
  'articles': 'Peer-Reviewed Articles',
  'tvshows': 'TV Shows',
  'bookreviews': 'Book Reviews',
  'dissertations': 'Dissertations',
  'books': 'Books',
  'translatedbooks': 'Translated Books',
  'films': 'Films',
  'documentaries': 'Documentaries',
  'webpages': 'Online Sources',
}
export const collectionNamesSingular = {
  'thinktankpapers': "Think Tank Publication",
  'thinktanktestimony': 'Congressional Testimony',
  'articles': 'Peer-Reviewed Article',
  'tvshows': 'TV Show',
  'bookreviews': 'Book Review',
  'dissertations': 'Dissertation',
  'books': 'Book',
  'translatedbooks': 'Translated Book',
  'films': 'Film',
  'documentaries': 'Documentary',
  'webpages': 'Online Source',
}


const allField =  {field: [], label: 'All Other Fields' }
const titleFields = ['Title', 'Original Title', 'Episode Title', 'Film', 'Hearing Title', 'Testimony Title']
const fieldsToBoost = [...titleFields, 'Topic', 'Production Regions', 'Region of Focus']
const advancedSearchFields = [
  {field: titleFields, label: 'Title'},
  {field: ['Author'], label: 'Author'},
  {field: ['Publisher'], label: 'Publisher'},
  {field: ['Abstract'], label: 'Abstract'},
  {field: ['Subjects', 'Topic Tropes'], label: 'Keywords'},
  allField
]
const hardcodedFields = advancedSearchFields.reduce((accum, next) => [...accum, ...next.field], [])
uniqueFields.forEach(field => {
  let exists = hardcodedFields.includes(field)
  if (!exists) {
    allField.field.push(field)
  }
})

const initialYear = 1979
const lastYear = new Date().getFullYear()
const selectYearList = []
for (let y = initialYear; y <= lastYear; y++) selectYearList.push(y)

export default function Search() {
  const [searchTerm, setSearchTerm] = useState('') // internet
  const [searchedTerm, setSearchedTerm] = useState(null)
  const listRefs = useRef({});
  const [accumulate, setAccumulate] = useState(true);
  const [searchOnUpdate, setSearchOnUpdate] = useState(false)
  const [scrollToCollectionAfterSearch, setScrollToCollectionAfterSearch] = useState(null)
  const [results, setResults] = useState([])
  const [totalResults, setTotalResults] = useState(-1)
  const [summaryData, setSummaryData] = useState([]);
  const [summaryYears, setSummaryYears] = useState([]);
  const [summaryTerm, setSummaryTerm] = useState('');
  const [searchInProgress, setSearchInProgress] = useState(false)
  const [peerReviewed, setPeerReviewed] = useState(false)
  const [advancedSearch, setAdvancedSearch] = useState(true)
  const [exactMatch, setExactMatch] = useState(false)
  const [startYear, setStartYear] = useState(initialYear)
  const [endYear, setEndYear] = useState(lastYear)
  const [showResults, setShowResults] = useState(false)
  const [searchFocused, setSearchFocused] = useState(false)
  const [searchString, setSearchString] = useState('');
  const [addToHistory, setAddToHistory] = useState(true);
  const [queryByYear, setQueryByYear] = useState(false);
  const [maxResultsReturned, setMaxResultsReturned] = useState(false)
  const {addSearchToHistory} = useSearchHistory()
  const user = useUser()

  useEffect(() => {
    const id = setTimeout(() => {
      setShowResults(true)
    }, process.env.NODE_ENV === 'production' ? 4000 : 0)
    return () => clearTimeout(id)
  }, [])

  const itemTypesSortedByRelevance = true
  const [advancedSearchFieldChecks, setAdvancedSearchFieldChecks] = useState(advancedSearchFields.map(_ => true))

  const history = useHistory();

  const search = useCallback(async (addToHistory = true) => {
    // auto()
    try {
      if (searchInProgress) {
        return
      }
      setSearchInProgress(true)
      let finalSearchTerm = searchTerm.trim()
      setSearchedTerm(finalSearchTerm)

      const hasTextSearch = finalSearchTerm !== '';

      const request = {
        method: 'post',
        url: `${process.env.REACT_APP_ELASTIC_NODE}/myindex/_search`,
        data: {
          query: {
            bool: {
              filter: [
                {
                  range:  { 'Year': { gte: startYear, lte: endYear }}
                }
              ],
            }
          },
          size: maxResults,
        },
        headers: {
          'Authorization': `ApiKey ${process.env.REACT_APP_ELASTIC_TOKEN}`
        },
        crossDomain: true,
      }


      // peer reviewed is articles... book reviews and some books that have a flag
      // query for all these types and then filter books on response...
      // probably a better way to do that
      if (peerReviewed) {
        request.data.query.bool.filter.push({
          terms: { 'type': ['bookreviews', 'articles', 'books'] }
        })
      }

      if (hasTextSearch) {
        // request.data.min_score = 1
        const listOfFields = advancedSearchFields
          .map(d => d.field)
          .filter((_, i) => advancedSearchFieldChecks[i])
          .reduce((f, n) => [...f, ...n], [])
        const boostedFields = listOfFields
          .map(field => {
            if (field === 'Region of Focus') {
              return `${field}^150`
            } else if (fieldsToBoost.includes(field)) {
              return `${field}^3`
            }
            return field
          })
        if (exactMatch) {
          request.data.query.bool.must = {
            'multi_match': {
              query: finalSearchTerm,
              fields: boostedFields,
              type: exactMatch ? 'phrase' : 'best_fields'
            }
          }
        } else {
          const wildcardSearchTerm = finalSearchTerm.split(' ').map(d => `${d}*`).join(' ')
          // request.data.query.bool.must = {
          //   'query_string': {
          //     query: wildcardSearchTerm,
          //     fields: fieldsToQuery,
          //     // type: 'best_fields'
          //   }
          // }
          request.data.query.bool.should = [...boostedFields.map(field => {
            const [fieldName, boost] = field.split('^')
            return { wildcard: { [fieldName]: { value: finalSearchTerm, boost: boost ? parseInt(boost, 10) : 1 }}}
          }),
            ...boostedFields.map(field => {
              const [fieldName, boost] = field.split('^')
              return { wildcard: { [fieldName]: { value: wildcardSearchTerm, boost: boost ? parseInt(boost, 10) : 1 }}}
            }),
            {
              'multi_match': {
                query: finalSearchTerm,
                fields: boostedFields,
                type: exactMatch ? 'phrase' : 'best_fields'
              }
            }

          ]
        }
      }

      const qbY = searchTerm.trim() === '' && startYear === endYear;
      setQueryByYear(q => q ? qbY : q);
      const searchString = `?q=${searchTerm}&ex=${exactMatch ? 1 : 0}&sy=${startYear}&ey=${endYear}&c=${advancedSearchFieldChecks.map(c => c ? 1 : 0)}&pr=${peerReviewed ? 1 : 0}&qy=${qbY ? 1 : 0}`;
      setSearchString(searchString);
      if (addToHistory) {
        history.push({ search: searchString });
        if (user && user.data) {
          addSearchToHistory(searchString)
        }
      }
      console.log(request)
      const results = await axios(request)
      console.log(results)
      setSearchInProgress(false)
      if (results.data && results.data.hits && results.data.hits.hits) {
        let hits = results.data.hits.hits
        setMaxResultsReturned(hits.length === maxResults)
        // filter peer review books
        if (peerReviewed) {
          hits = hits.filter(hit => {
            const source = hit._source
            if (source.type === 'books') {
              return source['Peer Reviewed'] === '1'
            }
            return true
          })
        }
        setResults(hits)
        const totalResults = results.data.hits.total.value || (searchTerm.length ? 0 : -1);
        setTotalResults(totalResults)
        setSummaryTerm(searchTerm);
      } else {
        setResults([])
        const totalResults = searchTerm.length ? 0 : -1;
        setTotalResults(totalResults)
        setSummaryTerm('');
      }
    } catch (error) {
      console.log(error)
    }
  }, [history, searchTerm, exactMatch, startYear, endYear, advancedSearchFieldChecks, peerReviewed, searchInProgress, addSearchToHistory, user])

  const updateSearchTerm = (event) => {
    setSearchTerm(event.target.value)
  }

  const keyDown = (event) => {
    if (event.keyCode === 13 && searchTerm.trim().length) {
      search(true);
    }
  }

  useEffect(() => {
    if (searchOnUpdate) {
      setSearchOnUpdate(false)
      search(addToHistory);
      setAddToHistory(true);
    }
  }, [searchOnUpdate, addToHistory, search])

  useEffect(() => {
    let handle;
    if (!searchInProgress && results.length > 0 && scrollToCollectionAfterSearch) {
      const delay = 0.5;
      handle = setTimeout(() => {
        if (listRefs.current && listRefs.current[scrollToCollectionAfterSearch]) {
          listRefs.current[scrollToCollectionAfterSearch].scrollIntoView({ behavior: 'smooth' })
          setScrollToCollectionAfterSearch(null)
        }
      }, delay);
    }
    return () => clearTimeout(handle);
  }, [results, scrollToCollectionAfterSearch, searchInProgress]);

  useEffect(() => {
    const parseQuery = (locationSearch) => {
      if (locationSearch && locationSearch !== searchString) {
        const parsedQuery = parse(locationSearch);
        if (parsedQuery.q) {
          const searchTerm = parsedQuery.q;
          setSearchTerm(s => s === searchTerm ? s : searchTerm);
        }
        if (parsedQuery.ex) {
          const exactMatch = +parsedQuery.ex ? true : false;
          setExactMatch(e => e === exactMatch ? e : exactMatch);
        }
        if (parsedQuery.sy) {
          const startYear = +parsedQuery.sy;
          setStartYear(y => y === startYear ? y : startYear);
        }
        if (parsedQuery.ey) {
          const endYear = +parsedQuery.ey;
          setEndYear(y => y === endYear ? y : endYear);
        }
        if (parsedQuery.c) {
          const advancedSearchFieldChecks = parsedQuery.c.split(',').map(c => +c ? true : false);
          setAdvancedSearchFieldChecks(c => c.join() === advancedSearchFieldChecks.join() ? c : advancedSearchFieldChecks);
        }
        if (parsedQuery.pr) {
          const peerReviewed = +parsedQuery.pr ? true : false;
          setPeerReviewed(p => p === peerReviewed ? p : peerReviewed);
        }
        if (parsedQuery.qy) {
          const queryByYear = +parsedQuery.qy ? true : false;
          setQueryByYear(q => q === queryByYear ? q : queryByYear);
        }
        setAddToHistory(false);
        setSearchOnUpdate(true);
      }
    }

    parseQuery(history.location.search);
    const unlisten = history.listen(({ search }) => parseQuery(search));
    return () => unlisten();
  }, [history, searchString]);

  const hasSearchString = useMemo(() => (
    history.location.search.length && (summaryTerm !== '' || queryByYear) ? true : false
  ), [history.location.search.length, queryByYear, summaryTerm]);

  useEffect(() => { // formatting data for summary display
    /*
      setting up placeholder data for default heat map display
      eventually these default values will be precalculated

      data issues:
      - multiple Year values per field
      - undefined Year values
      - undefined Date values
      - irregular / nonexistant Date field names
    */

    const usePlaceholder = results.length || hasSearchString ? false : true;

    if (usePlaceholder) {
      const summaryData = defaultSummaryData.map(d => {
        d.years = d.years.filter(y => +y.year >= initialYear);
        return d;
      });
      summaryData.sort((a, b) => {
        if (itemTypesSortedByRelevance) {
          return b.years[b.years.length - 1].cumulative - a.years[a.years.length - 1].cumulative
        } else {
          return a.key.localeCompare(b.key)
        }
      })
      const allYears = [...new Set(summaryData.map(d => d.years.map(y => y.year)).reduce((a, v) => a.concat(v), []))].sort();
      setSummaryYears(allYears);
      setSummaryData(summaryData);
    } else {
      const cleanData = results
        .map(d => ({ ...d._source, metadata: d }))
        .filter(d => d && d.Year >= initialYear);

      cleanData.sort((a, b) => {
        if (itemTypesSortedByRelevance) {
          return b.metadata._score - a.metadata._score
        } else {
          const typeCompare = a.type.localeCompare(b.type)
          if (typeCompare === 0) {
            return b.metadata._score - a.metadata._score
          } else {
            return typeCompare
          }
        }
      })
      const allYearsWithGaps = [...new Set(cleanData.map(d => d.years).reduce((a, v) => a.concat(v), []))].sort();
      const allYears = []
      for (let year = allYearsWithGaps[0]; year <= allYearsWithGaps[allYearsWithGaps.length - 1]; year++) {
        allYears.push(`${year}`)
      }
      setSummaryYears(allYears);

      const summaryData = mapToNest(group(cleanData, d => d.type))
        .map(type => {
          let cumulative = 0;
          type.years = allYears.map(year => {
            const values = type.values.filter(d => d.years.includes(year))
            const count = values.length;
            cumulative += count;
            return {
              year,
              values,
              count,
              cumulative,
            };
          });
          return type;
        });
      setSummaryData(summaryData);
    }

  }, [results, itemTypesSortedByRelevance, hasSearchString]);

  const reset = () => {
    setSearchTerm('')
    setSummaryTerm('');
    setResults([])
    setTotalResults(-1)
    const searchString = `?q=${''}&ex=${exactMatch ? 1 : 0}&sy=${startYear}&ey=${endYear}&c=${advancedSearchFieldChecks.map(c => c ? 1 : 0)}&pr=${peerReviewed ? 1 : 0}&qy=${queryByYear ? 1 : 0}`;
    setSearchString(searchString);
    history.push({ search: searchString });
  }

  const fullReset = useMemo(() => function() {
    setSearchTerm('');
    setSummaryTerm('');
    setResults([])
    setTotalResults(-1)
    setExactMatch(false);
    setStartYear(initialYear);
    setEndYear(lastYear);
    setAdvancedSearchFieldChecks(f => f.map(f => true));
    setPeerReviewed(false);
    setQueryByYear(false);
    setSearchString('');
    history.push({ search: '' });
  }, [history]);

  const resultsContent = useMemo(() => {
    let resultsContent = null
    if (!showResults) {
      return resultsContent
    }
    if (totalResults !== -1) {
      const resultsByType = Array.from(group(results, d => d._source.type))
      resultsByType.sort((a, b) => {
        if (itemTypesSortedByRelevance) {
          return b[1][0]._score - a[1][0]._score
        } else {
          return a[0].localeCompare(b[0])
        }
      })
      if (resultsByType.length === 0) {
        resultsContent = <div style={{ marginTop: '2em'}}>No results for "{searchedTerm}"</div>
      } else {
        resultsContent = resultsByType.map(([collection, values]) => (
          <ResultsList
            key={collection}
            collection={collection}
            values={values}
            listRefs={listRefs}
            summaryData={summaryData}
            summaryDataFilter={d => d.key === collection}
            itemTypesSortedByRelevance={itemTypesSortedByRelevance}
            showSearchResults
            accumulate={accumulate}
            clickToSearch={({ type, value }) => {
              const fields = advancedSearchFields.map(f => {
                return f.field.includes(type);
              });
              setAdvancedSearchFieldChecks(fields);
              setSearchTerm(value);
              setExactMatch(true);
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
              setSearchOnUpdate(true)
            }}
          />
        ))
      }
    } else {
      resultsContent = summaryData.map(({key, years}) => (
        <ResultsList
          key={key}
          collection={key}
          values={[]}
          listRefs={listRefs}
          summaryData={summaryData}
          summaryDataFilter={d => d.key === key}
          itemTypesSortedByRelevance={itemTypesSortedByRelevance}
          accumulate={accumulate}
        />
      ))
    }
    return (
      <React.Fragment>
        <h3 className='subhead'>
          <span>
            Knowledge Production Sources
          </span>
        </h3>
        {resultsContent}
      </React.Fragment>
    )
  }, [accumulate, itemTypesSortedByRelevance, results, searchedTerm, summaryData, totalResults, showResults])

  const toggleAdvancedSearchFields = (fieldIndex) => checked => {
    setAdvancedSearchFieldChecks(fields => {
      const newFields = [...fields]
      newFields[fieldIndex] = checked
      return newFields
    })
  }

  const heatmapClickHandler = useCallback((year, collection) => {
    setQueryByYear(true);
    setStartYear(year)
    setEndYear(year)
    setSearchOnUpdate(true)
    setScrollToCollectionAfterSearch(collection)
  }, [])

  const closeYearBanner = useCallback(() => {
    if (searchTerm.trim().length) {
      setStartYear(initialYear);
      setEndYear(lastYear);
      setQueryByYear(false);
      setSearchOnUpdate(true);
    } else {
      fullReset();
    }
  }, [fullReset, searchTerm]);

  const yearBanner = useMemo(() => {
    const [activeYear] = summaryYears;
    return (
      <div className={classnames('yearBanner', { active: (queryByYear || startYear === endYear) && results.length })}>
        <div className='label'>
          <span>Showing Publications for</span>
        </div>
        <select
          className='activeYear'
          value={activeYear}
          onChange={(event) => heatmapClickHandler(event.target.value, null)}
        >
          {selectYearList.map(y => {
            return <option key={y} value={y}>{y}</option>
          })}
        </select>
        <div
          className='close'
          onClick={closeYearBanner}
        >
          <span>Close</span>
          <img src={close} alt='x'/>
        </div>
      </div>
    );
  }, [summaryYears, queryByYear, heatmapClickHandler, startYear, endYear, results.length, closeYearBanner]);

  const maxResultsBanner = maxResultsReturned ?
    <div className='maxResultsBanner yearBanner active'>
      Max results returned. Please refine your search for more results
    </div> : null;
  return (

      <div className='Search'>
        <div className='searchControls'>
          {
            // <h3>
            //   <span>
            //     Knowledge Production Search
            //   </span>
            // </h3>
            // <div className='description'>
            //   <p>
            //     Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut ero labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.
            //   </p>
            // </div>
          }
          <div className='search'>
            <div className={classnames('searchField', { notDefault: searchTerm !== '' })}>
              <input
                type="text"
                onFocus={() => setSearchFocused(true)}
                onBlur={() => setSearchFocused(false)}
                value={searchTerm}
                className='field'
                onChange={updateSearchTerm}
                onKeyDown={keyDown}
                placeholder='Search by Title, Author, Publisher, Keywords or Peer Reviewed'
              />
              <div className={classnames('close', { searchFocused })} onClick={reset} />
            </div>
            <button
              disabled={searchInProgress}
              className={classnames('button', { faded: searchInProgress })}
              onClick={() => search(true)}
            >
              <span>Search</span>
              <div className={classnames('loader', { active: searchInProgress })}>
                <img src={loading} alt='' />
              </div>
            </button>
          </div>
          <div className='advancedControlsContainer'>
            <div className='advancedControlsToggleContainer'>
              <div className='advancedControlsToggle' onClick={() => setAdvancedSearch(advancedSearch => !advancedSearch)}>
                Advanced Search Features {' '}
                <img className={classnames('caret', {flip: advancedSearch})} src={caret} alt='show or hide advanced search features' />

                <div style={{ display: 'inline-flex' }} className={classnames('hintTooltip', { visible: true })}>
                  <div className='hintIcon'>
                    <img src={question} alt='?' />
                  </div>
                  <div className='hintContent'>
                    All search fields are selected by default. To refine your search, deselect fields you are not interested in. To search for a phrase (e.g. “Arab Spring”) or two or more words you wish to appear together, select “exact match.”
                  </div>
                </div>
              </div>
              <div className='fullReset' onClick={fullReset}>
                <div className='resetIcon'>
                  <img src={resetIcon} alt='' />
                  <img className='hover' src={resetHovered} alt='' />
                </div>
                <span>Reset</span>
              </div>
            </div>
            <div className={classnames('advancedControls', {visible: advancedSearch})}>
              <div className='fieldsAndYears'>
                <div className='fields'>
                  {advancedSearchFields.map(({field, label}, fieldIndex) => (
                    <Checkbox
                      setChecked={toggleAdvancedSearchFields(fieldIndex)}
                      checked={advancedSearchFieldChecks[fieldIndex]}
                      label={label}
                      key={field}
                    />
                  ))}
                </div>
                <div className='years'>
                  <div className={classnames('exactMatchContainer', {visible: advancedSearch})}>
                    <Toggle
                      name='exactMatch'
                      value={exactMatch}
                      setValue={setExactMatch}
                      label1='Contains'
                      label2='Exact match'
                    />
                  </div>
                  <div>
                    <div className='selects'>
                      <span>Year</span>
                      <select className='startYear' value={startYear} onChange={(event) => setStartYear(event.target.value)}>
                        {selectYearList.map(y => {
                          return <option key={y} value={y}>{y}</option>
                        })}
                      </select>
                      to
                      <select className='endYear' value={endYear} onChange={(event) => setEndYear(event.target.value)}>
                        {selectYearList.map(y => {
                          return <option key={y} value={y}>{y}</option>
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className='peerReview'>
                <Checkbox
                  setChecked={setPeerReviewed}
                  checked={peerReviewed}
                  label='Peer-Reviewed Only'
                />
              </div>
            </div>
          </div>
        </div>
        {/* <Toggle
          name='sortByRelevance'
          value={itemTypesSortedByRelevance}
          setValue={setItemTypesSortedByRelevance}
          label1='Alphabetically'
          label2='By Relevance'
        /> */}
        <div className='heatmapContainer'>
          <Toggle
            name='accumulate'
            value={accumulate}
            setValue={setAccumulate}
            label1='Year by year'
            label2='Accumulation'
            hintContent={[
              (<div key={0}><span><strong>Year by year:</strong> shows number of records created each year.</span></div>),
              (<div key={1}><span><strong>Accumulation:</strong> shows accumulation of records over time.</span></div>),
              (<div key={2}><span>This function controls all graphs below.</span></div>),
            ]}
          />
          <Heatmap
            inList={false}
            visible={results.length || (totalResults === -1 && !hasSearchString)}
            term={summaryTerm}
            collection={'Publications'}
            data={summaryData}
            years={summaryYears}
            listRefs={listRefs}
            itemTypesSortedByRelevance={itemTypesSortedByRelevance}
            accumulate={accumulate}
            rectClickHandler={heatmapClickHandler}
          />
          {yearBanner}
          {maxResultsBanner}
        </div>
        <div style={{ paddingTop: '0.5em'}}>
          {resultsContent}
        </div>
      </div>

  );
}