import {
  NavLink,
} from 'react-router-dom'

import logo from '../images/kpp-logo.svg';

import './styles.scss';
import { useUser } from 'reactfire'

export default function Header(props) {
  const { homeRoute, routes } = props;
  const user = useUser()

  return (
    <header>
      <div className='title'>
        <NavLink to={homeRoute.path}>
          <img src={logo} alt='' />
          <h1>
            <span>
              Knowledge Production Project
            </span>
          </h1>
        </NavLink>
      </div>
      <div className='buttons'>
        {routes.map(route => {
          if ('showToLoggedInUsers' in route) {
            const { showToLoggedInUsers } = route
            const loggedIn = user && user.data
            if ((showToLoggedInUsers && !loggedIn) || (!showToLoggedInUsers && loggedIn )) {
              return null
            }
          }
          return (
            <NavLink exact to={route.path} key={route.path} className='link' activeClassName='active'>
              <div className='button'>
                <span>
                  {route.label}
                </span>
              </div>
            </NavLink>
          )
        })}
      </div>
    </header>
  );
}
