import asiLogo from '../images/asi-logo.svg';
import kppLogoColor from '../images/kpp-logo-color.svg';
import twitterIcon from '../images/twitter.svg';
import twitterHoverIcon from '../images/twitterHover.svg';
import facebookIcon from '../images/facebook.svg';
import facebookHoverIcon from '../images/facebookHover.svg';

import './styles.scss';

// const title = encodeURIComponent('Archiving Knowledge Production on the Middle East');
// const url = encodeURIComponent(window.location);
const shareItems = [
  {
    id: 'Twitter',
    icon: twitterIcon,
    hoverIcon: twitterHoverIcon,
    url: 'https://twitter.com/4mespi',
    // url: `https://twitter.com/intent/tweet?url=${url}&text=${title}`,
  },
  {
    id: 'Facebook',
    icon: facebookIcon,
    hoverIcon: facebookHoverIcon,
    url: 'https://www.facebook.com/4mespi',
    // url: `https://www.facebook.com/sharer.php?u=${url}`,
  },
];

export default function Footer(props) {
  return (
    <footer>
      <div className='content'>
        <div className='logos'>
          <div className='logo'>
            <a href="http://www.arabstudiesinstitute.org/" target="_blank" rel="noreferrer">
              <img src={asiLogo} alt='Arab Studies Institute' />
            </a>
          </div>
          <div className='logo'>
            <a href="http://www.knowledgeproduction.com/" target="_blank" rel="noreferrer">
              <img src={kppLogoColor} alt='Knowledge Production Project' />
            </a>
          </div>
        </div>
        <div className='contact'>
          <div className='label'>
            <span>
              Knowledge Production Team
            </span>
          </div>
          <div className='link'>
            <a href='mailto:info@knowledgeproduction.com'>
              Contact Us
            </a>
          </div>
          <div className='link'>
            <a href='mailto:info@knowledgeproduction.com'>
              info@knowledgeproduction.com
            </a>
          </div>
        </div>
        <div className='social'>
          {shareItems.map(si => (
            <div key={si.id} className='shareItem'>
              <a href={si.url} target='_blank' rel='noopener noreferrer'>
                <img src={si.icon} alt={si.id} />
                <img src={si.hoverIcon} className='hover' alt={si.id} />
              </a>
            </div>
          ))}
        </div>
      </div>
      <div className='lines' />
    </footer>
  );
}
