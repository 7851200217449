import CollapsableContainer from "../ui/CollapsableContainer";
import { Link } from 'react-router-dom'
import './SearchHistory.scss'
import { useSearchHistory } from '../userHooks'
import { format } from 'date-fns'
export default function SearchHistory(props) {

  let table = 'No searches made yet'
  const { localSearchHistory } = useSearchHistory()
  console.log(localSearchHistory)
  if (localSearchHistory.length) {
    table = (
      <table>
        <thead>
          <tr><td>URL</td><td>Search Date</td></tr>
        </thead>
        <tbody>
          {localSearchHistory.map((searchItem, i) => {
            let search = null
            let time = null
            if (typeof searchItem === 'string') {
              search = searchItem
            } else {
              if (searchItem.search) {
                search = searchItem.search
              }
              if (searchItem.time) {
                time = format(new Date(searchItem.time), 'yyyy-MM-dd')
              }
            }
            const url = search ? `${window.origin}${process.env.PUBLIC_URL}${search}` : null
            return (
              <tr key={i}>
                <td><Link to={`/${search}`} className='link'>{url}</Link></td>
                <td>{time}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }
  return (
    <div className='SearchHistory'>
      <CollapsableContainer name='My Searches'>
        <div className='content'>
          <div>
            {table}
          </div>
        </div>
      </CollapsableContainer>
    </div>
  )
}
