import { useUser, useAuth } from "reactfire";
import CollapsableContainer from "../ui/CollapsableContainer";
import { useState } from 'react'

import './EditLoginInfo.scss'

export default function EditLoginInfo(props) {
  const user = useUser()
  const auth = useAuth()
  const [error, setError] = useState(null)
  const [status, setStatus] = useState(null)
  const [newEmail, setEmailAddress] = useState('')
  const [confirmEmail, setConfirmEmailAddress] = useState('')
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmNewPassword] = useState('')
  const [changeEmailPassword, setChangeEmailPassword] = useState('')

  let loggedInViewEmailPassword = false
  let firstProvider = null
  let email = null
  if (user && user.data && user.data.providerData) {
    user.data.providerData.forEach(providerData => {
      if (!firstProvider) {
        firstProvider = providerData.providerId
      }
      if (providerData.providerId === useAuth.EmailAuthProvider.PROVIDER_ID) {
        loggedInViewEmailPassword = true
        email = providerData.email
      }
    })
  }
  let content = null
  let updateFormField = updater => event => {
    updater(event.target.value)
  }

  const updateEmail = async () => {
    setError(null)
    setStatus(null)
    const newEmailToSet = newEmail.trim()
    if (newEmailToSet !== confirmEmail.trim()) {
      setError('Emails don\'t match, please confirm');
      return
    }

    try {
      await auth.signInWithEmailAndPassword(email, changeEmailPassword)
      // tried await below, but error wouldn't catch... so switched to then() & catch()
      user.data.updateEmail(newEmailToSet)
        .then(() => {
          setStatus('email updated')
        })
        .catch(error => {
          console.log(error)
          if (error.message) {
            setError(error.message)
          }
        })

    } catch (error) {
      console.log(error)
      if (error.message) {
        setError(error.message)
      }
    }
  }

  const updatePassword = async () => {
    console.log(oldPassword, newPassword, confirmPassword)
    setError(null)
    setStatus(null)
    if (newPassword !== confirmPassword) {
      setError('Passwords don\'t match, please confirm');
      return
    }

    try {
      await auth.signInWithEmailAndPassword(email, oldPassword)
      // tried await below, but error wouldn't catch... so switched to then() & catch()
      user.data.updatePassword(newPassword)
        .then(() => {
          setStatus('password updated')
        })
        .catch(error => {
          console.log(error)
          if (error.message) {
            setError(error.message)
          }
        })

    } catch (error) {
      console.log(error)
      if (error.message) {
        setError(error.message)
      }
    }
  }

  if (!loggedInViewEmailPassword) {
    content = <div className='edit'>You're currently logged in with {firstProvider}, login there to update your account.</div>
  } else {
    content = <div className='edit'>
      {error ? <div className='error'>{error}</div>: null }
      {status ? <div className='status'>{status}</div>: null }

      <div className='forms'>

        <div>
          <h4>Update Email Address</h4>
          <div style={{ fontSize: '0.875em'}}>
            Current Email Address: {email}
          </div>
          <input type="password" value={changeEmailPassword} placeholder="Password" onChange={updateFormField(setChangeEmailPassword)}/>
          <input type="text" value={newEmail} placeholder="New email address" onChange={updateFormField(setEmailAddress)}/>
          <input type="text" value={confirmEmail} placeholder="Confirm new email address" onChange={updateFormField(setConfirmEmailAddress)}/>
          <input type='submit' value="UPDATE" onClick={updateEmail} />
        </div>
        <div>
          <h4>Update Password</h4>
          <input type="password" value={oldPassword} placeholder="Old password" onChange={updateFormField(setOldPassword)}/>
          <input type="password" value={newPassword} placeholder="New password" onChange={updateFormField(setNewPassword)}/>
          <input type="password" value={confirmPassword} placeholder="Confirm new password" onChange={updateFormField(setConfirmNewPassword)}/>
          <input type='submit' value="UPDATE" onClick={updatePassword} />
        </div>

      </div>
    </div>
  }
  return (
    <div className='EditLoginInfo'>
      <CollapsableContainer name='Edit My Account'>
        <div className='content'>
          {content}
        </div>
      </CollapsableContainer>
    </div>
  )
}
