import React, { useEffect, useRef, useState, useCallback } from 'react';
import {
  BrowserRouter as Router,
  Route,
  NavLink,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom'
import classnames from 'classnames'
import { createPath } from 'history'
import ReactGA from 'react-ga4';

import Search from './Search'
import ThinkTanks from './ThinkTanks/'
import Networks from './Networks/'
import Header from './Header/'
import About from './About/'
// import Resources from './Resources/'
import Login from './Login/'
import Footer from './Footer/'
import SingleItem from './SingleItem/'
import Profile from './Profile'
import ModulePopup from './Modules/ModulePopup'

import './App.scss';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import bgVideo from './images/kpp-networks-loop-30s.mp4';

ReactGA.initialize('G-94QCVCBM37');

const routes = [
  {path: '/', label: 'Search', Component: Search },
  {path: '/think-tanks', label: 'Think Tanks', Component: ThinkTanks },
  {path: '/policy-networks', label: 'Policy Networks', Component: Networks },
]
const headerRoutes = [
  {path: '/about', label: 'About', Component: About },
  // {path: '/resources', label: 'Resources', Component: Resources },
  {path: '/login', label: 'Login', Component: Login, showToLoggedInUsers: false },
  {path: '/profile', label: 'Profile', Component: Profile, showToLoggedInUsers: true },
];
const headerPaths = headerRoutes.map(r => r.path);
const routesReversed = [...routes, ...headerRoutes].reverse()

export const ModulePopupContext = React.createContext({
  openModulePopup: () => {},
})

function CloseButton(props) {
  const history = useHistory();
  const goBack = () => {
    const closeRoute = window.localStorage.getItem('closeRoute')
    if (closeRoute) {
      history.push(closeRoute)
    } else {
      history.goBack()
    }
  }
  return (
    <div className='button close' onClick={goBack}>
      Close
    </div>
  );
}

function Navigation() {
  const location = useLocation();
  const showClose = headerPaths.includes(location.pathname);

  const lastRoute = useRef(location)
  useEffect(() => {
    if (showClose && !headerPaths.includes(lastRoute.current.pathname)) {
      const closeRoute = createPath(lastRoute.current)
      window.localStorage.setItem('closeRoute', closeRoute)
    }
  }, [showClose])
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search); // send pageview
    lastRoute.current = location
  }, [location])

  return (
    <nav>
      <div className={classnames('group', { active: !showClose })}>
        {routes.map(route =>
          <NavLink exact to={route.path} key={route.path}>
            <div className='button'>
              {route.label}
            </div>
          </NavLink>
        )}
      </div>
      <div className={classnames('group', { active: showClose })}>
        <CloseButton />
      </div>
    </nav>
  );
}

function ClearModulePopup(props) {
  const {clear} = props
  const location = useLocation()
  useEffect(() => {
    clear()
  }, [location, clear])

  return null
}

function App() {

  const [openModulePopup, setOpenModulePopup] = useState(null)
  const [forceCloseWarning, setForceCloseWarning] = useState(false);

  const value = {
    openModulePopup: (data) => {
      setOpenModulePopup(data)
    }
  }
  const clear = useCallback(() => {
    setOpenModulePopup(null)
  }, [])

  const modulePopup = openModulePopup ? <ModulePopup {...openModulePopup} /> : null
  return (
    <ModulePopupContext.Provider value={value}>
      <div className='App'>
        <Router basename={process.env.PUBLIC_URL}>
          <ClearModulePopup clear={clear} />
          <Header homeRoute={routes[0]} routes={headerRoutes} />
          <div className='subhead'>
            <h2>
              <span>
                Knowledge Production on the Middle East
              </span>
            </h2>
            <Navigation />
          </div>
          <div className='main'>
            <Switch>
            <Route path='/item/:itemId' children={<SingleItem />} />
            <Route path='/think-tanks/:thinkTankId' children={<ThinkTanks />} />
              {routesReversed.map(R =>
                <Route path={R.path} key={R.path}>
                  <R.Component />
                </Route>
              )}
            </Switch>
          </div>
          <Footer />
          {modulePopup}
        </Router>
        <div
          className='mobileWarning'
          style={{
            opacity: forceCloseWarning ? 0 : 1,
            pointerEvents: forceCloseWarning ? 'none' : 'auto',
          }}
        >
          <div className='content'>
            <div className='copy'>
              <span className='title'>
                Knowledge Production on the Middle East
              </span>
              <span>
               {` is optimized for desktop.`}
              </span>
            </div>
            <div className='main'>
              <video playsInline={true} autoPlay={true} muted={true} loop={true}>
                <source src={bgVideo} type="video/mp4" />
              </video>
            </div>
            <div className='buttons'>
              <a href={`mailto:?body=${encodeURIComponent(window.location)}&subject=${encodeURIComponent('Knowledge Production on the Middle East')}`}>
                <button className='button email'>
                  <span>
                    Email this page
                  </span>
                </button>
              </a>
              <button className='button continue' onClick={() => setForceCloseWarning(true)}>
                <span>
                  Continue Anyway
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModulePopupContext.Provider>
  );
}

export default App;
