import { useEffect, useState, useMemo } from 'react'
import classnames from 'classnames'
import sortBy from 'lodash.sortby';
import { extent } from 'd3-array';
import { format } from 'd3-format';
import { useCombobox } from 'downshift';
import { useSpring, animated } from 'react-spring';
import { easeCubic } from 'd3-ease';

import DetailedThinkTankView from './DetailedThinkTankView'
import CollapsableContainer from '../ui/CollapsableContainer'
import { useThinkTankData } from './thinkTankHooks'
import Toggle from '../ui/Toggle/';
import { Link, useParams, useLocation, useHistory } from 'react-router-dom'
import arrowDown from '../images/arrow-down.svg';
import caret from '../images/caret-sort.svg';
import slug from 'slug'
import './styles.scss'

const countFormatter = format(',.0f');
const currencyFormatter = format('$.2s');
const decimalCurrencyFormatter = format('$.2s');

function RangeChart(props) {
  const {
    first,
    last,
    min,
    max,
    avg,
    value,
    valueNote,
    formatter,
    valueFormatter,
    xIndex,
    yIndex,
  } = props;

  const avgPercent = avg ? ((avg - min) / (max - min)) * 100 : 0;
  const valuePercent = value ? ((value - min) / (max - min)) * 100 : 0;

  const delay = key => key === 'avgTransform'
    ? 0
    : Math.min(1260, (xIndex * 30) + ((yIndex + 1) * 120))
  const spring = useSpring({
    from: {
      avgTransform: `translate(0%, -100%)`,
      valueTransform: `translate(0%, 0%)`,
      valuePercent: `0%`,
    },
    to: {
      avgTransform: `translate(${avgPercent}%, -100%)`,
      valueTransform: `translate(${valuePercent}%, 0%)`,
      valuePercent: `${valuePercent}%`,
    },
    delay,
    duration: 240,
    easing: easeCubic,
  });

  return (
    <div className={classnames('RangeChart', { visible: value })}>
      <div className='label'>
        <span>
          {formatter(min)}
        </span>
      </div>
      <div className='range'>
        {
          <animated.div
            className={classnames('avgMarker', { first, last })}
            // style={{ transform: `translate(${avgPercent}%, -100%)` }}
            style={{ transform: spring.avgTransform }}
          >
            <div className='marker light' />
            <div className='marker dark' />
            {
              first ? (
                <div className='label'>
                  <span>
                    Average {formatter(avg)}
                  </span>
                  <img src={arrowDown} alt='V' />
                </div>
              ) : null
            }
          </animated.div>
        }
        <animated.div
          className='valueMarker'
          // style={{ transform: `translateX(${valuePercent}%)` }}
          style={{ transform: spring.valueTransform }}
        >
          <span>
            {valueFormatter(value)}
            <span className='note'>{valueNote}</span>
          </span>
          <div className='marker' />
        </animated.div>
        <div className='track'>
          <animated.div
            className='block'
            // style={{ width: `${valuePercent}%` }}
            style={{ width: spring.valuePercent }}
          />
        </div>
      </div>
      <div className='label'>
        <span>
          {formatter(max)}
        </span>
      </div>
    </div>
  );
}

export default function ThinkTanks(props) {
  const thinkTankData = useThinkTankData()
  let activeThinkTank = null
  const params = useParams()
  const location = useLocation()
  const history = useHistory()
  if (params && params.thinkTankId) {
    const matching = thinkTankData.find(d => slug(d.thinkTank) === params.thinkTankId);
    if (matching) {
      activeThinkTank = matching
    }
  }
  // const { allRevenueYears, latestRevenueYear } = useMemo(() => {
  const { allRevenueYears } = useMemo(() => {
    const allRevenueYears = sortBy([...new Set(
      thinkTankData
        .map(d => d.finances)
        .reduce((a, v) => a.concat(v), [])
        .filter(y => y.revenue > 0)
        .map(y => y.year)
    )], y => -y);
    // const [latestRevenueYear] = allRevenueYears;
    // return { allRevenueYears, latestRevenueYear };
    return { allRevenueYears };
  }, [thinkTankData]);

  const [revenueYear, setRevenueYear] = useState(2018); //useState(new Date().getFullYear() - 1);
  // useEffect(() => {
  //   setRevenueYear(ry => (ry === null || !allRevenueYears.includes(ry)) && latestRevenueYear !== undefined ? latestRevenueYear : ry);
  // }, [allRevenueYears, latestRevenueYear]);

  const [thinkTankSearchValue, setThinkTankSearchValue] = useState('')

  const search = (selectedThinkTank) => {
    const activeThinkTank = thinkTankData.find(t => t.thinkTank === selectedThinkTank) || null;
    if (activeThinkTank) {
      history.push(`/think-tanks/${slug(activeThinkTank.thinkTank)}`)
    }
  }

  const [allowYearSelect, setAllowYearSelect] = useState(true);

  const columns = useMemo(() => (
    [
      {
        label: 'Name',
        value: d => d.thinkTank,
      },
      {
        label: 'No. Congressional Testimonies',
        valueAccessor: d => d.numTestimonies,
        formatter: countFormatter,
        valueFormatter: countFormatter,
      },
      {
        label: 'Revenue',
        valueAccessor: (d) => {
          const [matchingYear] = allowYearSelect
            ? d.finances.filter(y => y.year === revenueYear && y.revenue >= 0)
            : d.finances.filter(y => y.year <= revenueYear && y.revenue > 0);
          if (matchingYear) {
            const note = allowYearSelect ? '' : `(${matchingYear.year})`;
            // matchingYear.year !== revenueYear ? `(${matchingYear.year})` : '';
            return [matchingYear.revenue, note];
          } else {
            return [null, null];
          }
        },
        formatter: currencyFormatter,
        valueFormatter: decimalCurrencyFormatter,
      },
      {
        label: 'Publications',
        valueAccessor: d => d.numPapers,
        formatter: countFormatter,
        valueFormatter: countFormatter,
      },
    ].map(column => {
      if (column.valueAccessor) {
        const allValues = thinkTankData
          .map(d => {
            const accessedValue = column.valueAccessor(d);
            if (Array.isArray(accessedValue)) {
              return accessedValue[0] || null;
            }
            return accessedValue;
          })
          .filter(d => d !== null);
        const [min, max] = extent(allValues);
        const avg = allValues.reduce((a, v) => a + v, 0) / allValues.length;
        column.min = min;
        column.max = max;
        column.avg = avg;
      }
      return column;
    })
  ), [thinkTankData, allowYearSelect, revenueYear]);

  const [sortKey, setSortKey] = useState(columns[0].label);

  const sortedThinkTankData = useMemo(() => {
    const column = columns.find(c => c.label === sortKey);
    const accessor = column.value || column.valueAccessor;
    return sortBy(thinkTankData, d => {
      const value = accessor(d);
      if (Array.isArray(value)) {
        return -value[0];
      } else {
        return -value;
      }
    });
  }, [thinkTankData, columns, sortKey]);

  useEffect(() => {
    if (activeThinkTank) {
      setThinkTankSearchValue(activeThinkTank.thinkTank)
    }
  }, [activeThinkTank])

  const closeThinkTank = () => {
    setThinkTankSearchValue('');
    setInputThinkTanks([]);
    history.push(`/think-tanks`)
  }

  let content = null
  let title = null
  if (activeThinkTank) {
    title = `${activeThinkTank.thinkTank} Congressional Testimonies / Revenue / Publications`
    content = (
      <div>
        <div className='buttons'>
          <button className='close' onClick={closeThinkTank}><span>Back to Think Tanks List</span></button>
        </div>
        <DetailedThinkTankView allThinkTanks={thinkTankData} thinkTank={activeThinkTank} />
      </div>
    )
  } else {
    title = 'Congressional Testimonies / Revenue / Publications'
    content = (
      <div className='rangeCharts'>
        <div className='revenueToggle'>
          <Toggle
            name='allowYearSelect'
            value={allowYearSelect}
            setValue={value => {
              setAllowYearSelect(value);
              // if (value) {
              //   setRevenueYear(latestRevenueYear);
              // }
            }}
            label1='Show Most Recent Year Revenue'
            label2='Show Selected Year Revenue Only'
          />
        </div>
        <table className='thinkTankList'>
          <thead>
            <tr>
              {columns.map(d => (
                <td key={d.label} className={d.label}>
                  <div className='label'>
                    <span>
                      {d.label}
                    </span>
                  </div>
                  {
                    d.label === 'Revenue' ? (
                      <div className={classnames('yearSelect', { visible: allowYearSelect })}>
                        <select
                          className='revenueYear'
                          value={revenueYear}
                          onChange={(event) => setRevenueYear(+event.target.value)}
                        >
                          {allRevenueYears.map(y => {
                            return <option key={y} value={y}>{y}</option>
                          })}
                        </select>
                      </div>
                    ) : null
                  }
                  <div
                    className={classnames('sortToggle', { active: d.label === sortKey })}
                    onClick={() => setSortKey(d.label)}
                  >
                    <span>Sort by</span>
                    <img src={caret} alt='^' />
                  </div>
                  <div className='sortTarget' onClick={() => setSortKey(d.label)} />
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedThinkTankData.map((thinkTank, thinkTankIndex) => {
              return (
                <tr
                  key={thinkTank.key}
                  className='fade'
                  style={{ animationDelay: `${thinkTankIndex * 120}ms` }}
                >
                  {columns.map((col, i) => {
                    let value = null
                    if (col.value) {
                      value = (
                        <Link
                          className='link'
                          to={`${location.pathname}/${slug(col.value(thinkTank))}`}
                        >
                          <span>{col.value(thinkTank)}</span>
                        </Link>
                      );
                    } else if (col.valueAccessor) {
                      let colValue, valueNote;
                      const accessedValue = col.valueAccessor(thinkTank);
                      if (Array.isArray(accessedValue)) {
                        [colValue, valueNote] = accessedValue;
                      } else {
                        colValue = accessedValue;
                      }
                      value = (
                        <RangeChart
                          {...col}
                          value={colValue}
                          valueNote={valueNote}
                          first={thinkTankIndex === 0}
                          last={thinkTankIndex === sortedThinkTankData.length - 1}
                          xIndex={i}
                          yIndex={thinkTankIndex}
                        />
                      )
                    }
                    return (
                      <td key={col.label} className={col.label}>{value}</td>
                    )
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    )
  }

  const thinkTankNames = thinkTankData.map(tt => tt.thinkTank);
  const [inputThinkTanks, setInputThinkTanks] = useState(thinkTankNames);

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    inputValue: thinkTankSearchValue,
    items: inputThinkTanks,
    onInputValueChange: ({ inputValue }) => {
      setThinkTankSearchValue(inputValue);
      setInputThinkTanks(
        inputValue.length >= 1 ? thinkTankNames.filter(item =>
          item.toLowerCase().startsWith(inputValue.toLowerCase()),
        ) : [],
      )
    },
    onSelectedItemChange: ({ selectedItem }) => {
      setThinkTankSearchValue(selectedItem);
      search(selectedItem);
    },
  })

  return (
    <div className='ThinkTanks'>
      <div className='thinkTankHeader'>
        <h3>Think Tanks Explorer</h3>
        <div className='instructions'>Know the think tank you are looking for? Search for it here.</div>
        {
        <div className='search' {...getComboboxProps()}>
          <div className='searchBar'>
            <div className={classnames('searchField', { notDefault: thinkTankSearchValue.length })}>
              <input
                placeholder='Search for Think Tank'
                type="text"
                className='field'
                {...getInputProps()}
              />
              <div className={'close'} onClick={closeThinkTank} />
            </div>
            <button
              className='button'
              {...getToggleButtonProps()}
              aria-label="toggle menu"
              onClick={() => search(thinkTankSearchValue)}
            >
              Search
            </button>
          </div>
          <div className='searchResults'>
            <ul
              className={classnames({ populated: isOpen && inputThinkTanks.length })}
              {...getMenuProps()}
            >
              {isOpen &&
                inputThinkTanks.map((item, index) => (
                  <li
                    className={classnames({ highlighted: highlightedIndex === index })}
                    key={`${item}${index}`}
                    {...getItemProps({ item, index })}
                  >
                    {item}
                  </li>
                ))}
            </ul>
          </div>
        </div>
        }
      {
        // <div className='search'>
        //   <div className={classnames('searchField', { notDefault: !!activeThinkTank })}>
        //     <input placeholder='Search for Think Tank' type="text" className='field' value={thinkTankSearchValue} onChange={updateThinkTankSearchValue} onKeyDown={thinkTankKeyDown} />
        //     <div className={'close'} onClick={closeThinkTank} />
        //   </div>
        //   <button className='button' onClick={search}>Search</button>
        // </div>
      }
      </div>
      <CollapsableContainer wrapperOnly name={title}>
        <div className='content'>
          {content}
        </div>
      </CollapsableContainer>
    </div>
  )
}
