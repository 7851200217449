import React, { useState, useEffect, useMemo, useRef, useContext } from 'react';
import sortBy from 'lodash.sortby';
import classnames from 'classnames';

import Heatmap from '../Heatmap/';
import SummaryDefaultChart from '../SummaryDefaultChart/'
import { collectionNames } from '../Search'
import DetailedItemView from '../DetailedItemView/'
import { ModulePopupContext } from '../App'
import { useMyStructuredModules } from '../userHooks';
import CollapsableContainer from '../ui/CollapsableContainer';
import OnlineSourcesTreemap from './OnlineSourcesTreemap';
// import WorldMap from '../WorldMap';

import nextArrow from '../images/next-arrow.svg';

import './styles.scss';

const linker = (url, title) => {
  return url && url.indexOf('http') === 0 ? <a href={url} target="_blank" rel='noreferrer' className='externalLink'>{title}</a> : null
}
const titleLinker = (linkKey, titleKey) => (row, value) => linker(row[linkKey], row[titleKey])

export const hintTypes = ['Author', 'Publisher', 'Translator', 'Journal', 'Editor'];

const types = {
  'thinktankpapers': {
    // exclude: ['Link'],
    customTD: {
      'Link': titleLinker('Link', 'Title')
    }
  },
  'bookreviews': {
    exclude: ['Publisher', 'Review Title']
  },
  'thinktanktestimony': {
    // exclude: ['Link', 'Year', 'Subcommittee', 'Administration', 'Witnesss Organization'],
    // customTD: {
    //   'Testimony Title': titleLinker('Link', 'Testimony Title')
    // }
    exclude: ['Testimony Title', 'Year', 'Subcommittee', 'Administration', 'Witnesss Organization'],
    customTD: {
      'Link': titleLinker('Link', 'Link')
    }
  },
  'webpages': {
    exclude: [ 'Year', 'Ready to go to MetaLayer', 'Sent to MetaLayer',
      'Send to MetaLayer Flag', 'InitialsDate Checked', 'RSS Feed',
      'Knowledge Flow', 'Knowledge Flow Variety', 'Language', 'Format Variety',
      'Keywords', 'Date original', 'Traffic month',

    ],
    customTD: {
      URL: (row, value) =>  value ? linker(value, 'Link') : '',
    }

  },
  films: {
    customTH: {
      'Production Companys': 'Production Company',
      'Links': 'Link',
    },
    customTD: {
      'Reviews': (row, value) => value ? linker(value, 'Reviews') : '',
      'Links': (row, value) => value ? linker(value, 'Links') : ''
    },
    exclude: ['Notes', 'Writers', 'Producers', 'Producer', 'Distributors', 'Production Company', 'Production Companys', 'LanguagesSubtitles', 'Domestic Box Office USD', 'Awards', 'Reviews', 'Topic Tropes', 'Notes', 'Viewed By', 'Viewed by', 'International Box Office USD', 'Story Region'], // 'Links',
  },
  dissertations: {
    exclude: ['Digital Object Identifiers', 'ISBN', 'Classifications', 'DissertationID', 'Abstract']
  },
  books: {
    customTD: {
      Link: (row, value) => value ? linker(value, 'Link') : '',
    },
    exclude: ['ISBN', 'Abstract' ,'Editor', 'Place of Publication', 'Peer Reviewed'],
  },
  'translatedbooks': {
    exclude: ['ISBN', 'Place of Publication', 'Abstract', 'Tags'],
  },
  documentaries: {
    exclude: ['Distributors', 'Genre', 'Link', 'Awards', 'Review', 'Notes', 'Producer'],
  },
  'tvshows': {
    exclude: ['Air Date', 'Channel', 'Director', 'Writer', 'Producer', 'Genre'],
  },
  articles:  {
    exclude: ['Abstract'],
  },
}

const columnOrder = ['MARKED',
  'Title', 'Episode Title', 'Film', 'Original Title', 'Hearing Title',
  'Year', 'TV Show ', 'Author', 'Editor', 'Publisher', 'URL', 'Date Digitalized',
  'Format', 'Topic', 'Geographic Focus', 'Country of Publication', 'City', 'Type'];

function chunkArray(array, chunkSize) {
  return Array(Math.ceil(array.length / chunkSize)).fill()
    .map((_, index) => index * chunkSize)
    .map(begin => array.slice(begin, begin + chunkSize));
}

const onlineSourceTypes = [
  {
    key: 'Region of Focus',
    label: 'Regions',
  },
  {
    key: 'Country of Focus',
    label: 'Countries'
  }
]


export default function ResultsList(props) {
  // const [metricIsPublication, setMetricIsPublication] = useState(true);
  const hintParent = useRef();
  const { collection, values, summaryData, listRefs, showSearchResults, clickToSearch, thinkTankList } = props;
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [resultSort, setResultSort] = useState(null);
  const [onlineSourceTypeFilters, setOnlineSourceTypeFilters] = useState(onlineSourceTypes.map(d => []));
  const allBookmarks = useMyStructuredModules().map(d => d.bookmarks)
    .reduce((p, cur) => [...p, ...cur], [])
    .map(d => d.itemId)

  const [tooltip, setTooltip] = useState({
    visible: false,
    hint: '',
    x: 0,
    y: 0,
  });
  const sortedValues = useMemo(() => {
    let v = [...values]
    if (resultSort !== null) {
      const { column, direction } = resultSort;
      v.sort((a, b) => {
        const aVal = a._source[column] || ''
        const bVal = b._source[column] || ''
        return aVal.localeCompare(bVal) * (direction === 'asc' ? 1 : -1)
      })
    }
    if (collection === 'webpages') {
      v = v.filter(d => {
        let show = true
        onlineSourceTypes.forEach((sourceType, sourceTypeIndex) => {
          const key = sourceType.key
          const value = d._source[key]

          if (onlineSourceTypeFilters[sourceTypeIndex].length > 0) {
            let foundInFilter = false
            onlineSourceTypeFilters[sourceTypeIndex].forEach(filterValue => {
              if (value && value.includes(filterValue)) {
                foundInFilter = true
              }
            })
            show = show && foundInFilter
          }
        })
        return show

      })
    }
    return v
  } , [values, resultSort, onlineSourceTypeFilters, collection]);
  const [page, setPage] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null)
  useEffect(() => {
    if (sortedValues) {
      setSelectedItem(null)
    }
  }, [sortedValues])
  const { openModulePopup } = useContext(ModulePopupContext)
  const data = chunkArray(sortedValues, resultsPerPage);
  const pageData = data[page];

  const paginationSteps = useMemo(() => {
    const start = Math.max(0, page - 4);
    let paginationSteps = data
      .map((d, i) => i)
      .slice(start, start + 8)
      .map(d => ({
        label: d + 1,
        index: d,
      }));
    if (!paginationSteps.find(d => d.index === 0)) {
      const hasSecond = paginationSteps.find(d => d.index === 1);
      paginationSteps = paginationSteps.concat([{
        label: hasSecond ? '1' : '1...',
        index: 0,
      }]);
    }
    if (!paginationSteps.find(d => d.index === data.length - 1)) {
      const hasSecondToLast = paginationSteps.find(d => d.index === data.length - 2)
      paginationSteps = paginationSteps.concat([{
        label: hasSecondToLast ? data.length : `...${data.length}`,
        index: data.length - 1,
      }]);
    }
    return sortBy(paginationSteps, d => d.index)
      .map(({ label, index }) => (
        <div
          key={index}
          className={classnames('page', { active: page === index })}
          onClick={() => setPage(index)}
        >
          <span>{label}</span>
        </div>
      ));
  }, [data, page]);

  const summaryYears = summaryData ? [...new Set(summaryData.map(d => d.years.map(y => y.year)).reduce((a, v) => a.concat(v), []))] : [];

  const type = types[collection]
  let name = collectionNames[collection]
  let list = null
  let count = null

  const clickRow = (row) => event => {
    if (!row) {
      return setSelectedItem(null)
    }
    setSelectedItem(row._source)
  }

  const updateResultsPerPage = (e) => {
    setResultsPerPage(e.target.value)
    setPage(0)
  }

  const hoverValue = e => {
    const { target } = e;
    const { dataset } = target;
    if (dataset.hasOwnProperty('hint')) {
      const { hint, value } = dataset;
      const visible = hint === 'HIDE' || value === '' ? false : true;
      if (visible) {
        const parent = hintParent.current
          ? hintParent.current.getBoundingClientRect()
          : listRefs.current[collection].getBoundingClientRect();
        const { x, y, height } = target.getBoundingClientRect();
        const xOffset = selectedItem ? -4 : 0;
        const pos = {
          x: x - parent.x + xOffset,
          y: y + (height - parent.y),
        };
        setTooltip({ visible, hint, ...pos });
      } else {
        setTooltip(tooltip => tooltip.visible ? { ...tooltip, visible: false } : tooltip);
      }
    }
  }

  const clearValue = () => setTooltip(tooltip => tooltip.visible ? { ...tooltip, visible: false } : tooltip);

  const sortColumn = (column) => () => {
    if (resultSort && resultSort.column === column) {
      setResultSort({ column, direction: resultSort.direction === 'asc' ? 'desc' : 'asc' });
    } else {
      setResultSort({ column, direction: 'asc' });
    }
  }
  let onlineSourcesContent = null

  if (showSearchResults) {
    let exclude = []
    if (type && type.exclude) {
      exclude = type.exclude
    }
    const colsToExclude = ['type', 'Volume', 'Issue', 'Tier', 'id', 'years',  ...exclude]
    // console.log(values)
    const headerSet = new Set()
    values.forEach(row => {
      const source = row._source
      const keys = Object.keys(source)
      keys.forEach(key => headerSet.add(key))
    })
    const headers = Array.from(headerSet)
    const columns = sortBy(
      ['MARKED'].concat(headers)
      .filter(col => !colsToExclude.includes(col))
      , col => columnOrder.indexOf(col) < 0 ? columnOrder.length : columnOrder.indexOf(col)
    );
    const openModuleTooltip = (data, alreadyBookmarked) => (event) => {
      openModulePopup({target: event.target, data, alreadyBookmarked})
    }

    const countLabel = sortedValues.length === 1 ? 'Result' : 'Results';
    count = `${sortedValues.length.toLocaleString()} ${countLabel}`

    if (collection === 'webpages') {
      onlineSourcesContent = (
        <OnlineSourcesTreemap types={onlineSourceTypes} onlineSourceTypeFilters={onlineSourceTypeFilters} setOnlineSourceTypeFilters={setOnlineSourceTypeFilters} data={values} />
      )
    }

    list = (
      <React.Fragment>
        <div className='list'>
          <table>
            <thead>
              <tr>
                {columns.map(column => (
                  <td key={column}>
                    <span onClick={sortColumn(column)} className='field'>
                      {
                        column === 'MARKED'
                          ? ''
                          : type && type.customTH && type.customTH[column]
                            ? type.customTH[column]
                            : column
                      }
                    </span>
                    {
                      resultSort && resultSort.column === column
                        ? (<span>
                          <svg
                            width={10}
                            height={10}
                            onClick={sortColumn(column)}
                            style={{ display: 'inline-block', margin: '0 0.5em', cursor: 'pointer',
                              transition: 'all 0.2s ease-in-out',
                              transform: resultSort.direction === 'desc' ? `rotate(90deg) translateX(20%)` : 'rotate(270deg) translateX(20%)'
                            }}>
                            <path
                              d={`M0,0 L5,5 L0,10 Z`}
                              fill='black'
                            />
                          </svg>
                          <span style={{ cursor: 'pointer' }} onClick={() => setResultSort(null)}>&times;</span>
                        </span>) : null
                    }
                  </td>
                ))}
              </tr>
            </thead>
            <tbody
              onMouseMove={hoverValue}
              onMouseOut={clearValue}
            >
              {pageData.map((row, valueIndex) =>
                <tr key={valueIndex}>
                  {columns.map((column, colIndex) => {
                    const data = row._source
                    const hint = clickToSearch && hintTypes.includes(column) ? column : 'HIDE';
                    const displayHint = hint !== 'HIDE';
                    const dataValue = hint === 'HIDE' ? '' : (data[column] || '').trim();
                    let value = (
                      <span>
                        {data[column]}
                      </span>
                    );
                    if (type && type.customTD && type.customTD[column]) {
                      value = (
                        <span>
                          {type.customTD[column](data, data[column])}
                        </span>
                      );
                    } else if (column === 'MARKED') {
                      const bookmarked = allBookmarks.includes(data.id)
                      value = <div className={classnames('bookmark', { marked: bookmarked })} onClick={openModuleTooltip(data, bookmarked)} />
                    }
                    let clickable = colIndex === 1
                    let onClick = clickable ? clickRow(row) : null
                    const isLink = value.props.children && value.props.children.type === 'a'

                    if (displayHint) {
                      onClick = () => clickToSearch({ type: hint, value: dataValue});
                    }
                    const style = {
                      textDecoration: displayHint || clickable ? 'underline' : null,
                      cursor: displayHint || clickable || isLink ? 'pointer' : 'auto',
                    }

                    return (
                      <td
                        key={column}
                        data-hint={hint}
                        data-value={dataValue}
                        style={style}
                        onClick={onClick}
                      >
                        {value}
                      </td>
                    );
                  })}
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className='pagination'>
          <div className='count'>
            <span>
              Showing {pageData.length.toLocaleString()} results of {sortedValues.length.toLocaleString()}
            </span>
          </div>
          <div className={classnames('selectors', { active: data.length > 1 })}>
            <div
              className={classnames('increment prev', { inactive: page === 0 })}
              onClick={() => (
                setPage(p => {
                  let page = p - 1;
                  if (page < 0) {
                    page = data.length - 1;
                  }
                  return page;
                })
              )}
              >
              <img src={nextArrow} alt='>' />
              <img src={nextArrow} alt='>' />
              <span>
                Prev
              </span>
            </div>
            <div className='pages'>
              {
                // data.map((d, i) => (
                //   <div
                //     key={i}
                //     className={classnames('page', { active: page === i })}
                //     onClick={() => setPage(i)}
                //   >
                //     <span>{i + 1}</span>
                //   </div>
                // ))
              }
              {
                // paginationSteps.map(({ label, index }) => (
                //   <div
                //     key={index}
                //     className={classnames('page', { active: page === index })}
                //     onClick={() => setPage(index)}
                //   >
                //     <span>{label}</span>
                //   </div>
                // ))
              }
              {paginationSteps}
            </div>
            <div
              className={classnames('increment next', { inactive: page === data.length - 1 })}
              onClick={() => (
                setPage(p => {
                  let page = p + 1;
                  if (page >= data.length) {
                    page = 0;
                  }
                  return page;
                })
              )}
              >
              <span>
                Next
              </span>
              <img src={nextArrow} alt='>' />
              <img src={nextArrow} alt='>' />
            </div>
            <span style={{ marginLeft: '1em'}}>
              Results Per Page:{' '}
              <select value={resultsPerPage} onChange={updateResultsPerPage}>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </span>
          </div>
        </div>
      </React.Fragment>
    )
  } else {
    const summaryDataRow = summaryData.find(d => d.key === collection)
    const summaryYears = summaryDataRow.years
    const lastCumulativeValue = summaryYears[summaryYears.length - 1].cumulative
    list = <SummaryDefaultChart accumulate={props.accumulate} collection={collection} data={summaryYears} fullSummaryData={summaryData}  />
    count = `(${lastCumulativeValue.toLocaleString()})`
  }

  return (
    <div
      key={collection}
      ref={r => listRefs.current[collection] = r}
      className='ResultsList'
    >
      <CollapsableContainer name={name} count={count}>
        <React.Fragment>
          <div
            ref={hintParent}
            className={classnames('itemWrapper', {itemOpen: selectedItem !== null})}
          >
            <div>
              {list}
              {
                (thinkTankList || collection === 'webpages') ? null :
                  <Heatmap
                    inList={true}
                    visible={true}
                    term={''}
                    collection={name}
                    data={summaryData}
                    filter={props.summaryDataFilter}
                    years={summaryYears}
                    listRefs={{current: {}}}
                    accumulate={props.accumulate}
                  />
              }
              {onlineSourcesContent}
              {
                // !thinkTankList && collection === 'webpages' ? (
                //   <WorldMap
                //     id='results'
                //     visible={values.length && !selectedItem}
                //     values={values}
                //     selected={null}
                //     metricIsPublication={metricIsPublication}
                //     setMetricIsPublication={setMetricIsPublication}
                //   />
                // ) : null
              }
            </div>
            <div>
              {
                selectedItem ? (
                  <DetailedItemView
                    close={clickRow(null)}
                    item={selectedItem}
                    clickToSearch={clickToSearch}
                    hoverValue={hoverValue}
                    clearValue={clearValue}
                    inThinkTankView={props.inThinkTankView}
                  />
                ) : null
              }
              {
                // !thinkTankList && collection === 'webpages' ? (
                //   <WorldMap
                //     id='selected'
                //     visible={values.length && selectedItem}
                //     values={values}
                //     selected={selectedItem}
                //     metricIsPublication={metricIsPublication}
                //     setMetricIsPublication={setMetricIsPublication}
                //   />
                // ) : null
              }
            </div>
            <div
              className={classnames('tooltip', { visible: tooltip.visible })}
              style={{ transform: `translate(${tooltip.x}px, ${tooltip.y}px)` }}
            >
              <span>
                {`Click to search by this ${tooltip.hint}`}
              </span>
            </div>
          </div>
        </React.Fragment>
      </CollapsableContainer>
    </div>
  );
}
