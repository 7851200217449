import { Toggle } from 'react-toggle-component';
import classnames from 'classnames';

import question from '../../images/question.svg';

import './styles.scss';

export default function MyToggle(props) {
  const { value, name, setValue, label1, label2, hintContent, style } = props

  return (
    <div className='Toggle' style={style}>
      <div className={classnames('label', { selected: !value })}>
        <span>
          {label1}
        </span>
      </div>
      <Toggle
        className='toggle'
        checked={value}
        name={name}
        controlled={true}
        onChange={e => setValue(e.target.checked)}
        backgroundColor='#1B1464'
        borderColor='#1B1464'
        knobColor='#ffffff'
        borderWidth='0.25em'
        width='1.5em'
        height='0.875em'
        knobWidth='0.625em'
        knobHeight='0.625em'
        knobRadius='0.25em'
      />
      <div className={classnames('label', { selected: value })}>
        <span>
          {label2}
        </span>
      </div>
      <div className={classnames('hintTooltip', { visible: hintContent })}>
        <div className='hintIcon'>
          <img src={question} alt='?' />
        </div>
        <div className='hintContent'>
          {hintContent}
        </div>
      </div>
    </div>
  )
}
