import { useState, useEffect, useRef, useMemo, useCallback } from 'react'; // useCallback - apply to handle functions?
import useDimensions from 'react-cool-dimensions';
import sortBy from 'lodash.sortby';
import { extent, group } from 'd3-array';
import { useCombobox } from 'downshift';
import classnames from 'classnames';
import ForceGraph3D from 'react-force-graph-3d';
import { CSS2DRenderer, CSS2DObject } from './CSS2DRenderer';

import Checkbox from '../ui/Checkbox';

import simpleConnectionsData from '../data/thinktank-connections-expanded.json';

import './styles.scss';

const extraRenderers = [new CSS2DRenderer()];

const introStepDuration = 2400;
const introStepHandles = [];
const stepFilter = {
  0: {
    highlightNode: true,
    nodeFilter: null,
  },
  1: {
    highlightNode: false,
    nodeFilter: node => node.type === 'expert',
  },
  2: {
    highlightNode: false,
    nodeFilter: node => node.type === 'thinktank',
  },
};

const typeColors = {
  // nonthinktank: '#f15a24',
  host: '241, 90, 36', // '#f15a24',
  expert: '165, 178, 181', //'99, 122, 129', // '#637a81', // '#a5b2b5', // '#d5d6e9',
  thinktank: '18, 96, 157', // #12609D //'127, 196, 253', // '#7fc4fd', // '#1b1464',
};

const typeLabels = {
  // nonthinktank: 'Non-Think Tank',
  host: 'Government Institution',
  expert: 'Expert',
  thinktank: 'Think Tank',
};

function setupNodes(data, key, type) {
  return (
    sortBy(
      [...new Set(simpleConnectionsData.map(d => d[key]))]
      .filter(d => d)
    ).map((d, i) => {
      return {
        name: d,
        id: `${type}-${i}`,
        key,
        type,
      };
    })
  );
}

function getNodeColor(node, highlightNodes, selectedNodes) {
  const colorValues = typeColors[node.type];
  if (highlightNodes.size || selectedNodes.size) {
    if (highlightNodes.has(node) || selectedNodes.has(node)) {
      return `rgba(${colorValues}, 1)`;
    }
    return `rgba(${colorValues}, 0.1)`;
  }
  return `rgba(${colorValues}, 1)`;
}

function getLinkColor(link, highlightLinks, selectedLinks) {
  if (highlightLinks.size || selectedLinks.size) {
    if (highlightLinks.has(link) || selectedLinks.has(link)) {
      return `rgba(99, 122, 129, 0.35)`;
    }
    return `rgba(99, 122, 129, 0.025)`;
  }
  return `rgba(99, 122, 129, 0.15)`;
}

function getNodeConnections(node, depth = 2) {
  const highlightNodes = new Set();
  const highlightLinks = new Set();
  if (node && depth > -1) {
    highlightNodes.add(node);
    if (Array.isArray(node.neighbors) && depth > 0) {
      node.neighbors.forEach(neighbor => highlightNodes.add(neighbor));
      //
      const expertNeigbors = depth > 1 ? node.neighbors.filter(n => n.type === 'expert') : [];
      if (expertNeigbors.length) {
        const oppositeType = node.type === 'host' ? 'thinktank' : 'host';
        expertNeigbors.forEach(neighbor => {
          if (Array.isArray(neighbor.neighbors)) {
            neighbor.neighbors.forEach(n => {
              if (n.type === oppositeType) {
                highlightNodes.add(n);
              }
            })
          }
          if (Array.isArray(neighbor.links)) {
            neighbor.links.forEach(l => {
              const addForTarget = (typeof l.target) === 'object'
                ? l.target.type === oppositeType
                : l.target.includes(oppositeType);
              const addForSource = (typeof l.source) === 'object'
                ? l.source.type === oppositeType
                : l.source.includes(oppositeType);
              if (addForTarget || addForSource) {
                highlightLinks.add(l);
              }
            });
          }
        });
      }
      //
    }
    if (Array.isArray(node.links) && depth > 0) {
      node.links.forEach(link => highlightLinks.add(link));
    }
  }
  return { highlightNodes, highlightLinks };
}

function removeDirecitonalLight(networkRef) {
  const directionalLight = networkRef.current.scene().children.find(c => c.type === 'DirectionalLight');
  if (directionalLight) {
    networkRef.current.scene().remove(directionalLight);
  }
}

const emptyNetwork = { nodes: [], links: [] };

export default function Networks(props) {
  const { observe, width, height } = useDimensions();

  const networkRef = useRef();
  const scrollRef = useRef();

  const [showThinktanks, setShowThinktanks] = useState(true);
  const [showExperts, setShowExperts] = useState(true);
  const [showHosts, setShowHosts] = useState(true);
  const typeStates = {
    thinktank: [showThinktanks, setShowThinktanks],
    expert: [showExperts, setShowExperts],
    host: [showHosts, setShowHosts],
  };
  //
  const [isIntro, setIsIntro] = useState(true);
  const [started, setStarted] = useState(false);
  const [explore, setExplore] = useState(false);
  const [introStep, setIntroStep] = useState(-1);
  //
  const [formattedData, setformattedData] = useState(emptyNetwork);
  const [connectionsData, setConnectionsData] = useState(emptyNetwork);
  const [highlightNode, setHighlightNode] = useState(new Set());
  const [highlightNodes, setHighlightNodes] = useState(new Set());
  const [highlightLinks, setHighlightLinks] = useState(new Set());
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedNodes, setSelectedNodes] = useState(new Set());
  const [groupedNodes, setGroupedNodes] = useState(new Set());
  const [selectedLinks, setSelectedLinks] = useState(new Set());
  const [networkSearchValue, setNetworkSearchValue] = useState('');
  const [closedDrawerSections, setClosedDrawerSections] = useState([])
  const [mouseOverGraph, setMouseOverGraph] = useState(false)
  function updateHighlight(highlightNodes, highlightLinks) {
    setHighlightNodes(highlightNodes);
    setHighlightLinks(highlightLinks);
  }

  const handleNodeHover = useCallback((node, highlightNode = true, nodeFilter = null, linkFilter = null) => {
    if (highlightNode) {
      setHighlightNode(node);
    }
    const { highlightNodes, highlightLinks } = getNodeConnections(node);
    updateHighlight(
      nodeFilter ? new Set([...highlightNodes].filter(nodeFilter)) : highlightNodes,
      linkFilter ? new Set([...highlightLinks].filter(linkFilter)) : highlightLinks,
    );
  }, []);

  const handleNodeClick = useCallback((node) => {
    const { highlightNodes, highlightLinks } = getNodeConnections(node);

    if (highlightNodes.size) {
      const nodeArray = Array.from(highlightNodes);
      const bounds = {
        x: extent(nodeArray.map(n => n.x)),
        y: extent(nodeArray.map(n => n.y)),
        z: extent(nodeArray.map(n => n.z)),
      };
      const differences = {
        x: (bounds.x[1] - bounds.x[0]),
        y: (bounds.y[1] - bounds.y[0]),
        z: (bounds.z[1] - bounds.z[0]),
      };

      // const distance = Math.hypot(differences.x, differences.y, differences.z);
      // const distRatio = 1 + distance / Math.hypot(node.x, node.y, node.z);

      const hypotDistance = Math.hypot(differences.x, differences.y, differences.z);
      const maxDistance = Math.max(differences.x, differences.y, differences.z) * 1.15;
      const distance = Math.min(maxDistance, hypotDistance);
      const distRatio = Math.max(1.15, 1 + distance / Math.hypot(node.x, node.y, node.z));

      networkRef.current.cameraPosition(
        {
          x: node.x * distRatio,
          y: node.y * distRatio,
          z: node.z * distRatio,
        },
        node,
        1200, // 1800,
      );
    }

    setNetworkSearchValue(node.name);
    setSelectedNode(node);
    setSelectedNodes(highlightNodes);
    const groupedNodes = group(highlightNodes, n => n.type);
    setGroupedNodes(groupedNodes);
    setSelectedLinks(highlightLinks);
  }, []);

  const networkNames = useMemo(() => connectionsData.nodes.map(n => n.name), [connectionsData.nodes]);
  const [inputNetworks, setInputNetworks] = useState(networkNames);

  function search(selectedNode) {
    const activeNode = connectionsData.nodes.find(t => t.name === selectedNode) || null;
    setSelectedNode(activeNode);
    handleNodeClick(activeNode);
  }

  function onInputValueChange({ inputValue }) {
    setNetworkSearchValue(inputValue);
    const punctuation = /[.!?\\-\\/]/g
    const testValue = inputValue.toLowerCase().replace(punctuation, '')
    setInputNetworks(
      inputValue.length >= 1 ? networkNames.filter(item =>
        item.toLowerCase().replace(punctuation, '').includes(testValue),
      ) : [],
    )
  }

  function onSelectedItemChange({ selectedItem }) {
    if (selectedItem) {
      setNetworkSearchValue(selectedItem);
      search(selectedItem);
    }
  }

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    reset,
  } = useCombobox({
    inputValue: networkSearchValue,
    items: inputNetworks,
    onInputValueChange,
    onSelectedItemChange,
  });

  const resetNetwork = useCallback((zoom = true) => {
    setClosedDrawerSections([])
    setNetworkSearchValue('');
    reset();
    setSelectedNode(null);
    setSelectedNodes(new Set());
    setSelectedLinks(new Set());
    updateHighlight(new Set(), new Set());
    if (zoom) {
      // networkRef.current.zoomToFit(1800, -150);
      networkRef.current.zoomToFit(1200, -75);
    }
  }, [reset]);

  useEffect(() => {
    const hosts = setupNodes(simpleConnectionsData, 'Government Host', 'host');
    const thinkTanks = setupNodes(simpleConnectionsData, 'Think Tank', 'thinktank');
    const experts = setupNodes(simpleConnectionsData, 'Expert Name', 'expert');
    const nodes = [].concat(hosts).concat(thinkTanks).concat(experts);

    const links = [];

    setformattedData({ nodes, links });
    // setConnectionsData({ nodes, links });
  }, []);

  useEffect(() => {
    const nodes = [];

    const hosts = formattedData.nodes.filter(n => n.type === 'host');
    if (showHosts) {
      hosts.forEach(node => {
        nodes.push({...node});
      });
    }

    const experts = formattedData.nodes.filter(n => n.type === 'expert');
    if (showExperts) {
      experts.forEach(node => {
        nodes.push({...node});
      });
    }

    const thinkTanks = formattedData.nodes.filter(n => n.type === 'thinktank');
    if (showThinktanks) {
      thinkTanks.forEach(node => {
        nodes.push({...node});
      });
    }

    const links = [];
    // hosts and experts
    if (showExperts && showHosts) {
      hosts.forEach(h => {
        const source = h.id;
        const targets = [...new Set(
          simpleConnectionsData
            .filter(c => c['Government Host'] === h.name)
            .map(t => t['Expert Name'])
          )].filter(t => t);
        targets.forEach(t => {
          const target = experts.find(e => e.name === t).id;
          links.push({
            source,
            target,
          });
        });
      });
    }
    // experts and think tanks
    if (showExperts && showThinktanks) {
      experts.forEach(e => {
        const source = e.id;
        const targets = [...new Set(
          simpleConnectionsData
            .filter(c => c['Expert Name'] === e.name)
            .map(t => t['Think Tank'])
          )].filter(t => t);
        targets.forEach(t => {
          const target = thinkTanks.find(tt => tt.name === t).id;
          links.push({
            source,
            target,
          });
        });
      });
    }
    // think tanks and host selected only
    if (showHosts && showThinktanks && !showExperts) {
      hosts.forEach(h => {
        const source = h.id;
        const targets = [...new Set(
          simpleConnectionsData
            .filter(c => c['Government Host'] === h.name)
            .map(t => t['Think Tank'])
          )].filter(t => t);
        targets.forEach(t => {
          const target = thinkTanks.find(tt => tt.name === t).id;
          links.push({
            source,
            target,
          });
        });
      });
    }

    //
    links.forEach(link => {
      const a = nodes.find(source => source.id === link.source);
      const b = nodes.find(target => target.id === link.target);
      !a.neighbors && (a.neighbors = []);
      !b.neighbors && (b.neighbors = []);
      a.neighbors.push(b);
      b.neighbors.push(a);

      !a.links && (a.links = []);
      !b.links && (b.links = []);
      a.links.push(link);
      b.links.push(link);
    });

    introStepHandles.forEach(h => clearTimeout(h));
    if (isIntro) {
      const introCenterNode = nodes.find(node => node.name === 'Department of State');
      const introNodeConnections = getNodeConnections(introCenterNode, introStep);
      const introNodes = Array.from(introNodeConnections.highlightNodes);
      const introLinks = Array.from(introNodeConnections.highlightLinks);
      //
      const z = 100 + (160 * introStep);
      networkRef.current.cameraPosition({ z }, null, introStepDuration);
      //
      if (started) {
        introStepHandles.push(
          setTimeout(() => {
            setConnectionsData({
              nodes: introNodes,
              links: introLinks,
            });
            handleNodeHover(null);
            const { highlightNode, nodeFilter } = stepFilter[introStep];
            introStepHandles.push(
              setTimeout(() => {
                handleNodeHover(introCenterNode, highlightNode, nodeFilter, () => false);
              }, introStepDuration / 2)
            );
            introStepHandles.push(
              setTimeout(() => {
                if (introStep < 2) {
                  setIntroStep(introStep + 1);
                } else {
                  const z = 100 + (160 * 2.5);
                  networkRef.current.cameraPosition({ z }, null, introStepDuration);
                  introStepHandles.push(
                    setTimeout(() => {
                      handleNodeHover(null);
                    }, introStepDuration)
                  );
                }
              }, introStepDuration)
            );
          }, introStep === -1 ? 0 : introStepDuration)
        );
      }
      //
    } else {
      resetNetwork(false); // just reset the selected node or something
      setConnectionsData({ nodes, links });
    }

    // setConnectionsData({ nodes, links });
  }, [formattedData, handleNodeHover, isIntro, started, introStep, showExperts, showThinktanks, showHosts, resetNetwork]);

  // do this after intro is over
  useEffect(() => {
    let zoomHandle;
    const enableZoom = e => {
      if (e.code.includes('Shift')) {
        networkRef.current.controls().enableZoom = true; // noZoom = trackballcontrols
      }
    }
    const disableZoom = e => {
      if (e.code.includes('Shift')) {
        networkRef.current.controls().enableZoom = false; // noZoom = trackballcontrols
      }
    }

    if (networkRef.current) {
      removeDirecitonalLight(networkRef);
      networkRef.current.d3Force('charge').distanceMax(300);

      if (!isIntro) {
        const delay = 1200;
        zoomHandle = setTimeout(() => {
          removeDirecitonalLight(networkRef);
          networkRef.current.zoomToFit(1200, -75);
        }, delay);
      }

      networkRef.current.controls().enableZoom = false; // noZoom = trackballcontrols
      window.addEventListener('keydown', enableZoom);
      window.addEventListener('keyup', disableZoom);
    }

    return () => {
      clearTimeout(zoomHandle);
      window.removeEventListener('keydown', enableZoom);
      window.removeEventListener('keyup', disableZoom);
    }
  }, [isIntro, connectionsData]);

  useEffect(() => {
    if (networkRef.current) {
      const orbit = !isIntro && (highlightNodes.size || selectedNodes.size || mouseOverGraph) ? false : true;
      networkRef.current.controls().autoRotate = orbit;
      networkRef.current.controls().autoRotateSpeed = 1;
    }
  }, [isIntro, highlightNodes.size, selectedNodes.size, mouseOverGraph]);

  const nodeThreeObject = useCallback(node => {
    let highlighted = highlightNodes.has(node);
    const selected = selectedNodes.has(node);
    const hidden = !highlighted && !selected; //
    if (highlighted || selected) {
      if (highlightNodes.size === 0 && selected) {
        highlighted = true;
      }
      let centerNode = false;
      if ((selectedNode && selectedNode.id === node.id) || (highlightNode && highlightNode.id === node.id)) {
        centerNode = true;
      }
      const nodeEl = document.createElement('div');
      nodeEl.className = `node-label ${hidden ? 'hidden' : ''} ${highlighted ? 'highlight' : ''} ${centerNode ? 'center' : ''}`;
      const nodeSpan = document.createElement('span');

      nodeSpan.textContent = node.name;
      nodeEl.appendChild(nodeSpan);
      return new CSS2DObject(nodeEl);
    }
    return null;
  }, [highlightNodes, selectedNodes, highlightNode, selectedNode]);

  const nodeColor = useCallback(node => getNodeColor(node, highlightNodes, selectedNodes), [highlightNodes, selectedNodes]);
  // const nodeVal = useCallback(node => highlightNodes.has(node) ? 3 : 1, [highlightNodes]);
  // const linkWidth = useCallback(link => highlightLinks.has(link) ? 3 : 1, [highlightLinks]);
  const linkColor = useCallback(link => getLinkColor(link, highlightLinks, selectedLinks), [highlightLinks, selectedLinks]);
  // const d3VelocityDecay = useMemo(() => showExperts ? 0.7 : 0.4, [showExperts]);

  // const networkGraph = useMemo(() => (
  const networkGraph = (
    <ForceGraph3D
      ref={networkRef}
      width={width}
      height={height}
      nodeVal={1}
      nodeColor={nodeColor}
      linkWidth={1}
      linkColor={linkColor}
      extraRenderers={extraRenderers}
      nodeThreeObject={nodeThreeObject}
      d3VelocityDecay={0.7}
      onNodeHover={handleNodeHover}
      onNodeClick={handleNodeClick}
      onBackgroundClick={resetNetwork}
      graphData={connectionsData}
      controlType={'orbit'}
      backgroundColor={'rgba(0, 0, 0, 0)'}
      nodeOpacity={1}
      linkOpacity={1}
      nodeResolution={3}
      linkResolution={2}
      // cooldownTicks={isIntro ? Infinity : 40}
      cooldownTime={isIntro ? introStepDuration : 1200}
      nodeThreeObjectExtend={true}
      showNavInfo={false}
      enableNodeDrag={false}
      // onEngineStop={() => {
      //   if (isIntro && introStep < 2) {
      //     clearTimeout(introHandle);
      //     introHandle = setTimeout(() => setIntroStep(introStep + 1), 900);
      //   }
      // }}
    />
  );
  // ), [
  //   width,
  //   height,
  //   connectionsData,
  //   resetNetwork,
  //   handleNodeClick,
  //   handleNodeHover,
  //   linkColor,
  //   nodeColor,
  //   nodeThreeObject,
  // ]);


  const drawerContent = useMemo(() => {
    const toggleDrawerSection = (key) => event => {
      setClosedDrawerSections(sections => {
        const index = sections.indexOf(key)
        let newSections = []
        if (index === -1) {
          newSections = [...sections, key]
        } else {
          newSections = [...sections]
          newSections.splice(index, 1)
        }
        return newSections
      })
    }
    return Array.from(groupedNodes).map(([key, values], i) => {
      const plural = i !== 0 && values.length ? 's' : '';
      const closed = closedDrawerSections.includes(key)
      return (
        <div key={key} className='group'>
          <div className={classnames('heading', { closed })} onClick={toggleDrawerSection(key)}>
            <span>{`${typeLabels[key]}${plural} (${values.length})`}</span>
          </div>
          <div className={classnames('list', { closed })}>
            {values.map(v => (
              <div
                key={v.id}
                className='node'
                onClick={() => handleNodeClick(v)}
                onMouseOver={() => handleNodeHover(v)}
                onMouseOut={() => handleNodeHover(null)}
              >
                <div className='circle' style={{ backgroundColor: `rgb(${typeColors[key]})` }}/>
                <div className='name'>
                  <span>{v.name}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    });
  }, [groupedNodes, handleNodeHover, handleNodeClick, closedDrawerSections]);

  const onMouseMoveGraph = (event) => {
    setMouseOverGraph(true)
  }
  const onMouseOffGraph = event => {
    setMouseOverGraph(false)
  }
  useEffect(() => {
    let timeout = null
    if (mouseOverGraph) {
      timeout = setTimeout(() => {
        setMouseOverGraph(false)
      }, 3000)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [mouseOverGraph])

  const startButton = (
    <button
      className={classnames('button', { active: introStep < 1 })}
      onClick={() => {
        setIntroStep(0);
        setStarted(true);
        scrollRef.current.scrollIntoView({ behavior: 'smooth' });
      }}
    >
      <span>
        Click to Start
      </span>
    </button>
  );

  return (
    <div className='Networks'>
      <div className='networkHeader'>
        <h3>Policy Networks 2012-2013 (Beta)</h3>
        <div className='instructions'>Explore the connections between Think Tanks, Organizations, and the Experts who work for them. You can view connections of people, think tanks, or organizations. This visualization reflects data gathered in 2012 and 2013.</div>
        <div className={classnames('search', { active: !isIntro })} {...getComboboxProps()}>
          <div ref={scrollRef} className='searchBar'>
            <div className={classnames('searchField', { notDefault: networkSearchValue.length })}>
              <input
                placeholder='Search for Name, Think Tank, or Organization'
                type="text"
                className='field'
                {...getInputProps()}
              />
              <div className={'close'} onClick={resetNetwork} />
            </div>
            <button
              className='button'
              {...getToggleButtonProps()}
              aria-label="toggle menu"
              onClick={() => search(networkSearchValue)}
            >
              Search
            </button>
            <div className={classnames('introOverlay', { active: isIntro })}>
              {startButton}
            </div>
          </div>
          <div className='searchResults'>
            <ul
              className={classnames({ populated: isOpen && inputNetworks.length })}
              {...getMenuProps()}
            >
              {isOpen &&
                inputNetworks.map((item, index) => (
                  <li
                    className={classnames({ highlighted: highlightedIndex === index })}
                    key={`${item}${index}`}
                    {...getItemProps({ item, index })}
                  >
                    {item}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
      <div className='networkContainer'>
        <div className='controls'>
          <div className='navInfo'>
            <span>
              Instructions:
            </span>
            <span>
              <strong> Rotate</strong> with Click + Drag,
            </span>
            <span>
              <strong> Zoom</strong> with Shift + Mouse-wheel/Scroll on touchpad,
              </span>
            <span>
              <strong> Pan</strong> with Right Click/Two finger press + drag
            </span>
          </div>
          <div className={classnames('legend', { isIntro })}>
            {
              Object.keys(typeColors).map(type => {
                const color = `rgba(${typeColors[type]}, 1)`;
                const label = typeLabels[type];
                const [checked, setChecked] = typeStates[type];
                return (
                  <div
                    key={type}
                    className={classnames('type', { checked })}
                    onClick={() => setChecked(c => !c)}
                  >
                    <Checkbox disabled={isIntro} checked={checked} setChecked={setChecked} />
                    <div className='block' style={{ backgroundColor: color }} />
                    <div className='label'>
                      <span>{label}</span>
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
        <div
          ref={node => observe(node)}
          className='network'
          onMouseMove={onMouseMoveGraph}
          onMouseOut={onMouseOffGraph}
        >
          {networkGraph}
          <div
            className={classnames('drawer', { open: selectedNode !== null })}
            style={{ width: Math.min(255, width) }}
          >
            <div className='container'>
              <button className='reset' onClick={resetNetwork}>
                <span>Close/Reset X</span>
              </button>
              <div className='panel'>
                {drawerContent}
              </div>
            </div>
          </div>
          <div className={classnames('intro', { isIntro })}>
            <div className='content'>
              <span className='visible'>
                Policy Networks shows connections
              </span>
              <span className={classnames({ visible: introStep > -1 })}>
                between
              </span>
              <span className={classnames('type', 'host', { visible: introStep > -1 })}>
                Government Insitutions
              </span>
              <span className={classnames('type', 'expert', { visible: introStep > 0 })}>
                Experts
              </span>
              <span className={classnames({ visible: introStep > 1 })}>
                and
              </span>
              <span className={classnames('type', 'thinktank', { visible: introStep > 1 })}>
                Think Tanks
              </span>
            </div>
            <div className={classnames('explore', { visible: !started || (!explore && introStep > 1), delayed: !explore && introStep > 1 })}>
              <button
                className={classnames('button', { active: introStep > 1 })}
                // onClick={() => setIsIntro(false)}
                onClick={() => {
                  const delay = 1200;
                  networkRef.current.cameraPosition({ z: 1000 }, null, delay);
                  setExplore(true);
                  introStepHandles.push(setTimeout(() => {
                    setIsIntro(false);
                  }, delay));
                }}
              >
                <span>
                  Click to Explore
                </span>
              </button>
              {startButton}
            </div>
            <div
              className='skip'
              onClick={() => {
                const delay = introStep > -1 ? 1200 : 120;
                networkRef.current.cameraPosition({ z: 1000 }, null, delay);
                introStepHandles.forEach(h => clearTimeout(h));
                introStepHandles.push(setTimeout(() => {
                  setIsIntro(false);
                }, delay));
              }}
            >
              <button className='button'>
                <span>
                  skip intro
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
