import './styles.scss';
import { useAuth, useUser, useSigninCheck } from 'reactfire';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { useHistory } from 'react-router';
import Profile from '../Profile'

export default function Login(props) {
  const user = useUser()
  const signinCheck = useSigninCheck()
  const showLoginForm = signinCheck && signinCheck.data && signinCheck.data.signedIn === false

  const auth = useAuth()
  const history = useHistory()

  const uiConfig = {
    signInOptions: [
      // List of OAuth providers supported.
      useAuth.GoogleAuthProvider.PROVIDER_ID,
      // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
      useAuth.EmailAuthProvider.PROVIDER_ID,
   ],
    callbacks: {
      signInSuccessWithAuthResult: () => {
        history.push('/profile')
        return false
      }
    }
  }

  const showLoggedInContent = user && user.data

  return (
    <div className='Login'>
      {showLoginForm ?
        <div style={{ margin: '0 auto'}}>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
        </div>
      : null}
      {
        showLoggedInContent ?
        <Profile />
        : null
      }

    </div>
  );
}
