
import { useEffect, useState, useCallback } from 'react'
import { useUser, useFirestore, useFirestoreCollectionData } from 'reactfire'
import cloneDeep from 'lodash/cloneDeep'
import axios from 'axios'

const MAX_SEARCH_HISTORY_TO_STORE = 20

export function useMyModules() {
  const user = useUser()
  const firestore = useFirestore()
  const userModulesRef = firestore
    .collection('userData')
    .doc(user.data.uid)
    .collection('modules')

  const userModules = useFirestoreCollectionData(userModulesRef)

  return { userModules }
}

export function useMyStructuredModules() {

  const user = useUser()
  const firestore = useFirestore()
  const [modules, setModules] = useState([])
  useEffect(() => {
    const subscriptions = []
    const fetch = async () => {
      if (user.data) {
        const u = firestore
          .collection('userData')
          .doc(user.data.uid)
          .collection('modules')
          .onSnapshot(qs => {
            const latestModules = []
            let modulesDone = 0
            let modulesToDo = 0
            qs.forEach( (module, moduleIndex) => {
              const path = module.ref.path
              modulesToDo ++
              const m = module.data()
              m.id = module.ref.id
              m.bookmarks = []
              latestModules.push(m)
              const u = firestore.collection(`${path}/bookmarks`)
                .onSnapshot(bookmarkQs => {
                  m.bookmarks.length = 0
                  bookmarkQs.forEach(bookmarkRef => {
                    const bookmark = bookmarkRef.data()
                    bookmark.id = bookmarkRef.id
                    m.bookmarks.push(bookmark)
                  })
                  modulesDone ++
                  if (modulesDone >= modulesToDo) {
                    const newSet = cloneDeep(latestModules)
                    setModules(newSet)
                  }
                })
              subscriptions.push(u)
            })

          })

          subscriptions.push(u)
      }
    }
    fetch()
    return () => {
      subscriptions.forEach(unsub => unsub())
    }
  }, [user.data, firestore])

  return modules
}


export function useDeleteModule() {
  const user = useUser()
  const firestore = useFirestore()
  const deleteModule = async (module) => {
    // hmm not sure if the boomkarks subcollection will actually be deleted
    // that might be ok?
    await firestore
      .collection('userData')
      .doc(user.data.uid)
      .collection('modules')
      .doc(module.id)
      .delete()


  }
  return deleteModule
}

export function useDeleteBookmark() {
  const user = useUser()
  const firestore = useFirestore()
  const deleteModule = async (module, bookmark) => {
    await firestore
      .collection('userData')
      .doc(user.data.uid)
      .collection('modules')
      .doc(module.id)
      .collection('bookmarks')
      .doc(bookmark.id)
      .delete()

  }
  return deleteModule

}


export function useGetItemById(itemId) {
  let [item, setItem] = useState(null)
  useEffect(() => {
    const makeRequest = async () => {
      const request = {
        method: 'post',
        url: `${process.env.REACT_APP_ELASTIC_NODE}/myindex/_search`,
        data: {
          query: {
            query_string: {
              query : `"${itemId}"`,
              fields: ['id'],
            },
          },
          size: 1,
        },
        headers: {
          'Authorization': `ApiKey ${process.env.REACT_APP_ELASTIC_TOKEN}`
        },
        crossDomain: true,
      }


      const results = await axios(request)
      if (results.data.hits.hits[0]) {
        setItem(results.data.hits.hits[0]._source)
      }
    }
    makeRequest()
  }, [itemId])

  return item
}

export function useSearchHistory() {
  const firestore = useFirestore()
  const user = useUser()
  const [localSearchHistory, setLocalSearchHistory] = useState([])
  useEffect(() => {
    let unsub = null
    if (user.data) {

      unsub = firestore
        .collection('userData')
        .doc(user.data.uid)
        .onSnapshot(doc => {
          const profile = doc.data()
          let remoteHistory = profile && profile.searchHistory ? profile.searchHistory : []
          setLocalSearchHistory(remoteHistory)
        })
    }
    return () => {
      if (unsub) unsub()
    }

  }, [user.data, firestore])
  const addSearchToHistory = useCallback(async (search) => {
    if (user && user.data && user.data.uid) {
      const time = Date.now()
      const newHistory = [{search, time}, ...localSearchHistory]
      if (newHistory.length > MAX_SEARCH_HISTORY_TO_STORE) {
        newHistory.length = MAX_SEARCH_HISTORY_TO_STORE
      }
      firestore
        .collection('userData')
        .doc(user.data.uid)
        .set({
          searchHistory: newHistory,
        })

    }
  }, [user, firestore, localSearchHistory])
  return {localSearchHistory, addSearchToHistory}
}