import { useUser, useFirestore } from 'reactfire'
import './ModuleList.scss'
import { format } from 'date-fns'
import {useMyModules} from '../userHooks'


export default function ModuleList(props) {
  const { newItem, close } = props
  const user = useUser()
  const firestore = useFirestore()

  const {userModules} = useMyModules()

  let modules = []
  if (userModules.data) {
    modules = userModules.data
  }
  const firestoreStatics = useFirestore
  const timeStamp = {
    dateCreated: firestoreStatics.FieldValue.serverTimestamp(),
  }
  const addItemToModule = (module) => async event => {
    event.preventDefault()

    await firestore
      .collection('userData')
      .doc(user.data.uid)
      .collection('modules')
      .doc(module.NO_ID_FIELD)
      .collection('bookmarks')
      .add({
        itemId: newItem.id,
        ...timeStamp,
      })
    close()
  }

  if (modules.length === 0) {
    return null
  }
  return (
    <div className='ModuleList'>
      <table>
        <thead>
          <tr>
            <td>Module Name</td>
            <td>Date Created</td>
          </tr>
        </thead>
        <tbody>
          {modules.map((d, i) => {
            let name = d.name
            if (newItem) {
              name = <button className='link' onClick={addItemToModule(d)}>{name}</button>
            }
            return <tr key={i}>
              <td>{name}</td>

              <td>{d.dateCreated ? format(d.dateCreated.toDate(), 'PP') : '' }</td>
            </tr>
          })}
        </tbody>
      </table>
    </div>
  )
}


