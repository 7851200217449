import { useState } from 'react'
import classnames from 'classnames';
import { useMyStructuredModules, useDeleteModule, useDeleteBookmark } from "../userHooks"
import CollapsableContainer from "../ui/CollapsableContainer"
import { format } from 'date-fns'
import './ProfileModuleList.scss'
import { confirmAlert } from 'react-confirm-alert'; // Import
import { collectionNamesSingular } from '../Search'
import BookmarkName, { BookmarkNameFromId } from './BookmarkName';

function EmailButton(props) {
  const { text, email, module } = props;
  if (email && module) {
    const [baseURL] = window.location.href.split('/profile');
    const mailto = encodeURI(`mailto:${email}?subject=Knowledge Production Project: ${module.name} Module Bookmarks&body=${module.bookmarks.map(b => `${BookmarkNameFromId(b.itemId)}: ${baseURL}/item/${b.itemId} \r\n`)}`);
    return (
      <button
        className='email'
        onClick={() => window.open(mailto)}
      >
        {text}
      </button>
    );
  }
  return null;
}

export default function ProfileModuleList(props) {
  const { email } = props;
  const [selectedModuleIndex, setSelectedModuleIndex] = useState(-1)
  const modules = useMyStructuredModules()
  const selectedModule = selectedModuleIndex === -1 || !modules[selectedModuleIndex] ? null : modules[selectedModuleIndex]

  const clickModule = (moduleIndex) => event => {
    setSelectedModuleIndex(moduleIndex)
  }
  const close = () => setSelectedModuleIndex(-1)

  const deleteModuleAction = useDeleteModule()
  const confirmDeleteModule = module => event => {
    confirmAlert({
      title: 'Delete Module?',
      message: 'Are you sure you want to delete this module?',
      buttons: [
        { label: 'Delete', onClick: deleteModule(module) },
        { label: 'Keep', onClick: () => {} }
      ]
    })
  }
  const deleteModule = (module) => event => {
    deleteModuleAction(module)
    setSelectedModuleIndex(-1)
  }

  const deleteBookmarkAction = useDeleteBookmark()
  const confirmDeleteBookmark = bookmark => event => {
    confirmAlert({
      title: 'Delete Bookmark?',
      message: 'Are you sure you want to delete this bookmark?',
      buttons: [
        { label: 'Delete', onClick: () => deleteBookmarkAction(selectedModule, bookmark) },
        { label: 'Keep', onClick: () => {} }
      ]
    })
  }

  const title = `My Modules${selectedModule ? `: ${selectedModule.name}` : ''}`
  const count = selectedModule ?
    `(${selectedModule.bookmarks.length} bookmark${selectedModule.bookmarks.length === 1 ? '' : 's'})` :
    `(${modules.length} module${modules.length === 1 ? '' : 's'})`
  return (
    <div className='ProfileModuleList'>
      <CollapsableContainer name={title} count={count}>
        <div className={classnames('itemWrapper', {itemOpen: selectedModule !== null})}>
          <div>
            <table>
              <thead>
                <tr>
                  <td>Name</td>
                  <td>Items</td>
                  <td>Created On</td>
                  <td>Email</td>
                  <td>Delete</td>
                </tr>
              </thead>
              <tbody>
                {modules.map((module, moduleIndex) => {
                  return <tr key={module.id}>
                    <td><button className='link' onClick={clickModule(moduleIndex)}>{module.name}</button></td>
                    <td>{module.bookmarks.length}</td>
                    <td>{module.dateCreated ? format(module.dateCreated.toDate(), 'PP') : '' }</td>
                    <td>
                      <EmailButton
                        text='Email To Me'
                        email={email}
                        module={module}
                      />
                    </td>
                    <td><button className='delete' onClick={confirmDeleteModule(module)}>Delete</button></td>
                  </tr>
                })}
              </tbody>
            </table>
          </div>
          <div>
            <div className='buttons'>
              <button className='close' onClick={close}><span>Back to all modules</span></button>
              <button className='delete' onClick={confirmDeleteModule(selectedModule)}><span>Delete this module</span></button>
            </div>
            <div className='buttons' style={{ justifyContent: 'flex-end' }}>
              <EmailButton
                text={<span>Email this module to myself</span>}
                email={email}
                module={selectedModule}
              />
            </div>
            {
              selectedModule ? (
                <table>
                  <thead>
                    <tr>
                      <td></td>
                      <td>Collections In This Module</td>
                      <td>Type</td>
                      <td>Added On</td>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedModule.bookmarks.map((bookmark, index) => {
                      const {itemId} = bookmark
                      let bookmarkType = itemId.split('-')[0]
                      if  (collectionNamesSingular[bookmarkType]) {
                        bookmarkType = collectionNamesSingular[bookmarkType]
                      }
                      return <tr key={index}>
                        <td><button className='delete' onClick={confirmDeleteBookmark(bookmark)}>Delete this bookmark</button></td>
                        <td><BookmarkName itemId={itemId} /></td>
                        <td>{bookmarkType}</td>
                        <td>{bookmark.dateCreated ? format(bookmark.dateCreated.toDate(), 'PP') : '' }</td>
                      </tr>
                    })}
                  </tbody>
                </table>
              ) : null
            }
          </div>
        </div>
      </CollapsableContainer>
    </div>
  )
}