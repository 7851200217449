import useDimensions from "react-cool-dimensions";
import { scaleLinear } from 'd3-scale'
import { extent } from 'd3-array'

import './styles.scss'
import { useRef, useState } from "react";
import { zeroAdjustedColorScale } from '../Heatmap'
import { useMemo } from "react";

const pMap =(variable1, min1, max1, min2, max2) => min2+(max2-min2)*((variable1-min1)/(max1-min1))



export default function SummaryDefaultChart(props) {
  const { data, accumulate } = props
  const [tooltip, setTooltip] = useState(null)
  const svgRef = useRef()


  let { observe, width } = useDimensions();

  const margins = { top: 25, right: 1, bottom: 30, left: 180 }
  const svgWidth = width
  width = svgWidth - margins.left - margins.right
  const metricKey = accumulate ? 'cumulative' : 'count'
  // valueExtent, xScale, xTickArray, yearExtent, yScale
  const rectHeightCount = 10
  const { valueExtent, xScale, xTickArray, yearExtent, yScale, rectWidth, height, svgHeight } = useMemo(() => {
    const yearExtent = extent(data, row => +row.year)

    const valueExtent = extent(data, row => row[metricKey])

    const xScale = scaleLinear()
      .domain([yearExtent[0], yearExtent[1] + 1])
      .range([0, width])

    const rectWidth = xScale(yearExtent[0] + 1)
    const height = Math.max(0, rectHeightCount * rectWidth)
    const svgHeight = height + margins.top + margins.bottom
    const yScale = scaleLinear()
      .domain([0, valueExtent[1]])
      .range([height, 0])
      .nice()
    valueExtent[1] = yScale.domain()[1]

    const xTickArray = xScale.ticks()
    return { valueExtent, xScale, xTickArray, yearExtent, yScale, rectWidth, height, svgHeight}
  }, [data, margins.bottom, margins.top, metricKey, width])


  let mousemove = (event) => {
    const {left, top} = svgRef.current.getBoundingClientRect()
    const x = event.clientX - margins.left - left
    const y = event.clientY - margins.top - top
    const year = Math.min(yearExtent[1], Math.max(yearExtent[0], Math.round(xScale.invert(x - rectWidth / 2))))

    setTooltip({
      x,
      y,
      year
    })
  }

  let mouseoff = () => {
    setTooltip(null)
  }

  let tooltipDiv = null
  const tooltipYear = tooltip ? tooltip.year : null
  let content = useMemo(() => {
    if (svgWidth > 0) {
      const xTicks = xTickArray.map(tick => {
        const x = Math.round(xScale(tick)) + 0.5 + rectWidth / 2
        return (
          <g key={x} transform={`translate(${x}, 0)`}>
            <text textAnchor='middle' y={height + 20} className='darkGrey'>{tick}</text>
          </g>
        )
      })


      // const yTicks = yTickArray.map((tick, tickIndex) => {
      const yTicks = []
      for (let i = 0; i <= rectHeightCount; i++) {
        const tick = Math.round(i / rectHeightCount * valueExtent[1])
        const y = yScale(tick) + 4
        yTicks.push(
          <g key={y} transform={`translate(0, ${y})`}>
            <line y={0} x2={-7} className='darkGreyStroke' />

            <text textAnchor='end' x={-20} y={4} className='darkGrey'>{tick.toLocaleString()}</text>
          </g>
        )
      }
      // })

      // const yearTicks = data.map(d => {
      //   if (xTickArray.includes(+d.year)) {
      //     return null
      //   }
      //   return (
      //     <g key={d.year} transform={`translate(${xScale(d.year)},0)`} opacity={0.15}>
      //       <line className='darkGreyStroke' strokeDasharray='5,5' y2={height} stroke='red' />
      //     </g>
      //   )
      // })



      const rects = []
      for (let yearIndex = 0; yearIndex <= (yearExtent[1] - yearExtent[0]); yearIndex++) {
        const year = yearExtent[0] + yearIndex
        const yearValue = data[yearIndex][metricKey]
        const fill = zeroAdjustedColorScale(yearValue)
        for (let row = 0; row < rectHeightCount; row ++) {
          const x = xScale(year)
          const y = height - row * rectWidth - rectWidth
          const key = `${year}-${row}`
          const rectPadding = 1
          const rectSizeWithPadding = rectWidth - rectPadding * 2
          // const backgroundRectColor = '#ff0000'
          const backgroundRectColor = '#E7EDEF'

          const backgroundRectOpacity = Math.floor(row / 2) % 2 === 0 ? '0.2' : '0.5'
          const fullRectValue = (row + 1) / rectHeightCount * valueExtent[1]
          const unfilledRectValue = (row ) / rectHeightCount * valueExtent[1]
          const dataRectHeightPercentage = Math.max(0, Math.min(1, pMap(yearValue, unfilledRectValue, fullRectValue, 0, 1)))
          const dataRectHeight = dataRectHeightPercentage * rectSizeWithPadding
          const dataRectColor = fill
          const dataRectOpacity = tooltipYear ? ( tooltipYear === year ? 1 : 0.2 ) : 1 // fullRectValue < yearValue ? 1 : 0
          rects.push(
            <g transform={`translate(${x + rectPadding}, ${y + rectPadding})`} key={key}>
              <rect rx={2} title={year} width={rectSizeWithPadding} height={rectSizeWithPadding} y={rectWidth - rectSizeWithPadding} opacity={backgroundRectOpacity} fill={backgroundRectColor} />
              <rect rx={2} title={year} width={rectSizeWithPadding} y={rectWidth - dataRectHeight} height={dataRectHeight} fill={dataRectColor} opacity={dataRectOpacity} />
            </g>
          )
        }
      }

      return <g>
        {xTicks}
        {yTicks}
        {rects}
      </g>
    }
    return null
  }, [data, height, metricKey, rectWidth, svgWidth, tooltipYear, valueExtent, xScale, xTickArray, yearExtent, yScale ])

  if (tooltip) {
    const year = tooltip.year
    let value = 0
    const exists = data.find(d => +d.year === year)
    if (exists) value = exists[metricKey]
    tooltipDiv = (
      <div className='chartTooltip' style={{ transform: `translate(${xScale(year) + margins.left + rectWidth / 2}px, ${margins.top + yScale(value) - 5}px) translate(-50%, -100%)`}}>
        {year}: {value.toLocaleString()}
      </div>
    )
  }



  return (
    <div ref={(node) => {
      observe(node)
      svgRef.current = node
    }} className='SummaryDefaultChart'>
      <svg width={svgWidth} height={svgHeight} onMouseMove={mousemove} onMouseOut={mouseoff}>
        <g transform={`translate(${margins.left}, ${margins.top})`}>
          {content}
        </g>
      </svg>
      {tooltipDiv}
    </div>
  )
}
