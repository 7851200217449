import { useGetItemById } from "../userHooks"
import { Link } from 'react-router-dom'

export function BookmarkNameFromId(itemId) {
  let name = ''
  const item = useGetItemById(itemId)
  if (item) {
    if (item['Title']) {
      name = item['Title']
    } else if (item['Hearing Title']) {
      name = item['Hearing Title']
    } else if (item['Episode Title']) {
      name = item['Episode Title']
    } else if (item['Film']) {
      name = item['Film']
    }
  }
  return name;
}

export default function BookmarkName(props) {
  const { itemId } = props
  const name = BookmarkNameFromId(itemId);
  return <div style={{ maxWidth: '40em'}}><Link to={`item/${itemId}`} target='_blank'>{name}</Link></div>
}
