import {useWindowWidth} from '@react-hook/window-size'
import { sum, extent } from 'd3-array'
import { useMemo, useState } from 'react'
import { scaleLinear } from 'd3-scale'
import { animated, useSprings} from 'react-spring'
const Graph = (props) => {
  const { width, height, data, type, click,onlineSourceTypeFilters } = props
  const [hoveredGroup, setHoveredGroup] = useState(null)
  const totalCount = sum(data, d => d.results.length)
  const colorExtent = extent(data, d => d.results.length)
  const xScale = scaleLinear()
    .domain([0, totalCount])
    .range([0, width])

  const opacityScale = scaleLinear()
    .domain(colorExtent)
    .range([0.4, 1])
  const marginTop = 5
  const hoverGroup = (group) => () => {
    if (!group) {
      // return
    }
    setHoveredGroup(group)
  }
  let runningX = 0
  let hoveredTooltip = null
  const springs = useSprings(data.length, data.map(d => {
    let y = marginTop
    let h = height
    if (onlineSourceTypeFilters.length && onlineSourceTypeFilters.includes(d.key)) {
      y = 0
      h += marginTop
    }
    return { y, height: h }
  }))
  const boxes = data.map((d, i) => {
    const x = runningX
    const w = xScale(d.results.length)
    runningX += w
    const clipPathId = `rect-clip-${Math.round(Math.random() * 10000)}`
    const finalRectWidth = Math.max(w - 3, 3)
    const opacity = opacityScale(d.results.length)
    const finalOpacity = onlineSourceTypeFilters.length === 0 ? opacity : onlineSourceTypeFilters.includes(d.key) ? opacity : 0.2
    const labelParts = d.key.split(' ')
    if (hoveredGroup === d.key) {
      const tooltipStyle = {
        transform: `translate(${x + finalRectWidth / 2}px, 0) translateX(-50%) translateY(-1.8em)`,
      }
      const lineLength = onlineSourceTypeFilters.includes(d.key) ? '0.5em' : null
      hoveredTooltip = (
        <div className="onlineSourcesTreemapTooltip" style={tooltipStyle}>
          <div className="tooltip-title">{d.key} {d.results.length} result{d.results.length === 1 ? '' : 's'}</div>
          <div className='line' style={{ height: lineLength }}/>
        </div>
      )
    }
    const spring = springs[i]
    return (
      <animated.g key={d.key} transform={ spring.y.to(y => `translate(${x}, ${y})`)}
        style={{ cursor: 'pointer' }}
        onMouseOver={hoverGroup(d.key)} onMouseOut={hoverGroup(null)} onClick={click(d.key)}>
        <clipPath id={clipPathId}>
          <rect width={w} height={height + marginTop} />
        </clipPath>
        <g clipPath={`url(#${clipPathId})`}>
          <animated.rect rx={3} width={finalRectWidth} height={spring.height} fill={'#12609D'}
            style={{ transition: 'opacity 0.3s ease-in-out', opacity: finalOpacity}} />
          <text fontWeight='bold'>
            {labelParts.map((part, i) => {
              return <tspan style={{ fill: '#fff' }} x={3} key={i} dy={`1.2em`}>{part}</tspan>
            })}
          </text>
        </g>
      </animated.g>
    )
  })
  return (
    <div>
      <div className='graphTitle'>{type.label}</div>
      <div style={{ position: 'relative' }}>
        <svg width={width} height={height + marginTop} style={{ overflow: 'visible'}}>
          {boxes}
        </svg>
        {hoveredTooltip}
      </div>
    </div>
  )
}
export default function OnlineSourcesTreemap(props) {
  const { data, types, onlineSourceTypeFilters, setOnlineSourceTypeFilters } = props
  // console.log(data)
  const windowWidth = useWindowWidth()
  // console.log(windowWidth)


  const groupedByType = useMemo(() => {
    return types.map(type => {
      const group = {}
      data.forEach(d => {

        const value = d._source[type.key]
        if (!value) {
          return
        }
        const valueParts = value.split(',').map(d => d.trim())
        valueParts.forEach(v => {
          if (!group[v]) {
            group[v] = []
          }
          group[v].push(d)
        })
      })
      const groupArray = Object.keys(group).map(key => {
        return {
          key,
          results: group[key]
        }
      })
      groupArray.sort((a, b) => {
        return b.results.length - a.results.length
      })
      return groupArray
    })
  }, [data, types])
  // console.log(groupedByType)

  const totalWidth = windowWidth - 60 - 60 - 40
  const graphWidth = totalWidth / types.length

  const graphHeight = 100

  let selectedFilters = null
  const click = i => (value) => () => {
    let newValues = onlineSourceTypeFilters[i]
    if (newValues.includes(value)) {
      newValues = newValues.filter(d => d !== value)
    } else {
      newValues = [...newValues, value]
    }
    const newSources = onlineSourceTypeFilters.map(d => [])
    newSources[i] = newValues
    setOnlineSourceTypeFilters(newSources)
  }

  if (onlineSourceTypeFilters[0].length || onlineSourceTypeFilters[1].length) {
    const filters = onlineSourceTypeFilters[0].length ? onlineSourceTypeFilters[0] : onlineSourceTypeFilters[1]
    const label = onlineSourceTypeFilters[0].length ? 'Regions' : 'Countries'
    const index = onlineSourceTypeFilters[0].length ? 0 : 1
    console.log(filters)
    selectedFilters = (
      <div className='onlineSourcesTreemapFilters'>
        <div className='onlineSourcesTreemapFiltersTitle'>Selected {label}:</div>
        <div className='onlineSourcesTreemapFiltersList'>
          {filters.map(filter => {
            return (
              <div className='onlineSourcesTreemapFiltersItem' key={filter}>
                <div className='onlineSourcesTreemapFiltersItemLabel'>{filter}</div>
                <div className='onlineSourcesTreemapFiltersItemClose' onClick={click(index)(filter)}>&times;</div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <div className="OnlineSourcesTreemap">
      <div>
        Click on any region or country to filter results online sources by your selection.
      </div>
      {selectedFilters}
      <div className='graphs'>
        {types.map((type, i) => {
          const group = groupedByType[i]

          return (
            <Graph key={type.key} type={type} data={group} width={graphWidth} height={graphHeight}
              onlineSourceTypeFilters={onlineSourceTypeFilters[i]}
              click={click(i)}
            />
          )
        })}
      </div>
    </div>
  )
}