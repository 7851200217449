import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'firebase/firestore';
import 'firebase/auth';

import { FirebaseAppProvider } from 'reactfire';
var firebaseConfig = {
  apiKey: "AIzaSyD51RplmOGARFbsY2jo6KvQn8B-HY_9ARA",
  authDomain: "knowledgeproductionproject.firebaseapp.com",
  projectId: "knowledgeproductionproject",
  storageBucket: "knowledgeproductionproject.appspot.com",
  messagingSenderId: "229481001432",
  appId: "1:229481001432:web:500bdd792a0978006282ad",
  measurementId: "G-B61P8N6MVW"
};

ReactDOM.render(
  <React.StrictMode>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <App />
    </FirebaseAppProvider>

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
