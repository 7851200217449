
import './ModulePopup.scss'
import useOnClickOutside from "use-onclickoutside";
import { ModulePopupContext } from '../App'
import { useContext } from 'react'
import { useRef } from 'react';
import { useUser } from 'reactfire'
import { Link } from 'react-router-dom'
import AddNewModule from './AddNewModule'
import ModuleList from './ModuleList'

const ModulePopup = (props) => {

  const { target, data, alreadyBookmarked } = props

  const { openModulePopup } = useContext(ModulePopupContext)

  const ref = useRef()
  const close = () => openModulePopup(null)
  useOnClickOutside(ref, (event) => {
    openModulePopup(null)
    event.preventDefault()
    event.stopPropagation()
  })
  const user = useUser()

  const loggedIn = user && user.data

  const position = target.getBoundingClientRect()
  let x = position.left
  const y = position.top + position.height + window.scrollY
  let flipX = false
  if (x > window.innerWidth * 0.8) {
    x += position.width
    flipX = true
  }
  const style = {
    transform: `translate(${x}px, ${y}px) ${flipX ? ' translateX(-100%)' : ''}`
  }

  const alreadyBookmarkedNotice = alreadyBookmarked ?
    <div className='alreadyBookmarked'>You've already bookmarked this item. View it in <Link className='link' to='/profile'>your profile</Link>.</div>
  : null

  const content = loggedIn ?
    <div>
      <h2>Add To Module</h2>
      {alreadyBookmarkedNotice}
      <ModuleList newItem={data} close={close} />
      <AddNewModule newItem={data} close={close} />
    </div> :
    <div>You must be <Link to='/login'>logged in </Link> to save items to a collection</div>


  return (
    <div ref={ref} className='ModulePopup' style={style}>{content}</div>
  )
}


export default ModulePopup
