import './styles.scss';

export default function About(props) {
  return (
    <div className='About'>
      <h3>
        About The Knowledge Production Project
      </h3>
      <p>
        The Knowledge Production Project (KPP) is an open-access archive which aims to gather and make available for analysis all knowledge produced on the Middle East in English since 1979.
      </p>
      <p>
        Understanding that the production of and access to knowledge is never an impartial or equitable process, the Knowledge Production Project creates new ways to identify and redress these disparities through innovative data visualizations and advanced search capacities.
      </p>
      <p>
        The KPP archive is now informed by eleven separate databases, compiling records of: <i>peer-reviewed journals, books, dissertations, translated books</i> (from Arabic to English), <i>book reviews, think tank publications, congressional testimonies, films, documentaries, television programs</i>, and <i>online sources</i>.
      </p>
      <p className='listIntro'>
        The Knowledge Production Project is composed of three sections:
      </p>
      <ol>
        <li data-marker='1)'>
          <span>
            A searchable archive and data visualization of 150,000 + unique records of academic, policy, and audio-visual production about the Middle East
          </span>
        </li>
        <li data-marker='2)'>
          <span>
            Think Tanks Explorer: a comparative catalogue providing searchable outputs of  publications, congressional testimonies, as well as financial information including revenues, net assets, and expenses, where such information is open-access
          </span>
        </li>
        <li data-marker='3)'>
          <span>
            Policy Networks: an immersive data visualization which illustrates how power circulates within relatively confined networks by portraying the movement of individual experts to and from policy analysis and policy implementation posts for the period of 2012-2013
          </span>
        </li>
      </ol>
      <h3>
        Team
      </h3>
      <p>
        KPP is powered by the work of dedicated database coordinators and research assistants. To learn more about the KPP team, click <a href='http://www.knowledgeproduction.com/Team' target='_blank' rel='noopener noreferrer'>here</a>.
      </p>
      <h3>
        Methodology
      </h3>
      <p>
        Each team has developed its own procedures for identifying sources, mining data, cataloguing and coding entries. To learn more about our databases and the methodology used by each team, click <a href='http://www.knowledgeproduction.com/Methodology' target='_blank' rel='noopener noreferrer'>here</a>.
      </p>
      <h3>
        Using The Search Tool
      </h3>
      <p>
        KPP’s rich archive contains thousands of records across eleven databases. To help users find the information they need more efficiently, KPP utilizes a powerful search tool with advanced search features. Users can search by Title, Author, Publisher, Abstract, Keywords, and All Other Fields. Additionally, the search can be further customized by searching for records within a specific time period.
      </p>
      <p className='listIntro'>
        <i>
          Tips for Searching and Viewing Results
        </i>
      </p>
      <ol>
        <li data-marker='•'>
          <span>
            Search results are displayed by relevance with records that have search terms in their “Title” field appearing first.
          </span>
        </li>
        <li data-marker='•'>
          <span>
            All fields (with the exception of “Peer-Reviewed Only”) are selected by default. To refine your search, deselect fields you are not interested in.
          </span>
        </li>
        <li data-marker='•'>
          <span>
            To search for a phrase or two or more words you wish to appear together, select “exact match.” <i>Example:</i> “Arab spring” or “Al Jazeera” will yield more relevant results when the “exact match” option is selected.
          </span>
        </li>
        <li data-marker='•'>
          <span>
            Selecting “Peer-Reviewed Only” will isolate the following peer- reviewed material: peer-reviewed articles, book reviews, and books published by university presses.
          </span>
        </li>
        <li data-marker='•'>
          <span>
            “All Other Fields” denotes all other searchable information that does not appear here (e.g. think tank name, production company, etc.).
          </span>
        </li>
        <li data-marker='•'>
          <span>
            Clicking the bookmark icon next to any record saves it to your modules.
          </span>
        </li>
      </ol>
      <h3>
        Reading the Heatmap and Sources Graphs
      </h3>
      <p>
        All data from KPP’s eleven databases feeds into the main heatmap on our landing page. Right below that are eleven individual bar graphs representing each source of knowledge production in the KPP archive. The colors on the heatmap and the bar graphs range from white to orange based on the number of records (bright orange indicating the highest number of records).
      </p>
      <p>
        There are two ways to view both the main heatmap and individual bar graphs: “year by year” and “accumulation” (to switch views use the toggle bar in the upper right corner above the heatmap). “Year by year” shows the number of records for each knowledge production source that were added to KPP’s databases each year, whereas “accumulation” shows the accumulation of these records over time (i.e. the total number of records added to the relevant KPP database up to that point).
      </p>
      <p>
        Clicking on any square of the main heatmap will display results for that particular knowledge production source in the given year. The selected year will remain true for the rest of the search unless you reset the search or click the “x” on the yellow box appearing below the heatmap.
      </p>
    </div>
  );
}
