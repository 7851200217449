
import { useState } from 'react'
import { useFirestore, useUser } from 'reactfire'
import './AddNewModule.scss'

const moduleNameMinLength = 2
export default function AddNewModule(props) {
  const { newItem, close } = props
  const [moduleName, setModuleName] = useState('')
  const [error, setError] = useState(null)
  const user = useUser()
  const firestoreStatics = useFirestore
  const firestore = useFirestore()
  const typeModuleName = (e) => {
    setModuleName(e.target.value)
  }

  const timeStamp = {
    dateCreated: firestoreStatics.FieldValue.serverTimestamp(),
  }
  const addModule = async (name) => {
    if (!user || !user.data || !user.data.uid) {
      console.log('no user object found')
      return
    }
    try {
      const newModule = await firestore
        .collection('userData')
        .doc(user.data.uid)
        .collection('modules')
        .add({
          ...timeStamp,
          name,
        })
      await firestore
        .collection(`${newModule.path}/bookmarks`)
        .add({
          itemId: newItem.id
        })
      close()
    } catch (error) {
      console.log('error adding', error)
    }
  }
  const clickAddButton = () => {
    const name = moduleName.trim()
    if (name.length < moduleNameMinLength) {
      return setError('Module names should be at least 2 characters')
    }
    setError(null)
    addModule(name)
  }

  const keyDown = e => {
    if (e.keyCode === 13) {
      return clickAddButton()
    }
  }
  return (
    <div className='AddNewModule'>
      {error ?
        <div className='error'>{error}</div>
        : null
      }
      <input placeholder='Create New Module' type="text" value={moduleName} onChange={typeModuleName} onKeyDown={keyDown} />
      <input type="submit" value='Create &amp; Add' onClick={clickAddButton} />
    </div>
  )
}