
import './styles.scss'

export default function Checkbox(props) {
  const { checked, setChecked, label } = props
  return (
    <div className='checkbox'>
      <input type='checkbox' checked={checked} onChange={e => setChecked(e.target.checked)} />
      <label onClick={() => setChecked(!checked)}><span>{label}</span></label>
    </div>
  )
}
